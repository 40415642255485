import React, { useRef } from "react"
import { CPPOptPlotConfig, CPPOptPlotJSON } from "../../../../Types/CPPOptPlot"
import { DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useRecoilValue } from "recoil"
import { cppOptPlotConfigsAtom } from "../../../../Atoms/CPPOpt"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { D3CPPOptPlot } from "../D3/D3CPPOptPlot"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { CPPOptToolbar } from "./CPPOptToolbar"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { ModalityPageManager } from "../../../../Data/ModalityPageManager"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"

type CPPOptPlotProps = {
    windowId: string,
    json: CPPOptPlotJSON
}

export const CPPOptPlot = (props: CPPOptPlotProps) => {

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 0 }}>
                <CPPOptToolbar windowId={props.windowId} />
            </div>
            <div style={{ flex: 1 }}>
                <DimensionsProvider>
                    <CPPOptPlotVisualization {...props} />
                </DimensionsProvider>
            </div>
        </div>
    )
}

const CPPOptPlotVisualization = (props: CPPOptPlotProps) => {
    const container = useRef<HTMLDivElement>(null)
    const { getDataQuerySocket } = useSocketProvider()

    const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
    const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
    const atom = cppOptPlotConfigsAtom(visualizationId)
    const { fileStartDate, fileEndDate} = useRecoilValue(currentPatientFileInfoAtom)

    const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.CPPOPT_PLOT, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })
    const [viewStart, viewEnd] = viewScale.domain()
    const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const d3Controller = useD3Controller<D3CPPOptPlot, CPPOptPlotConfig, CPPOptPlotJSON, ModalityPageManager>({
		atom,
		nodeRef: container,
		initProps: props.json,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.CPPOPT_PLOT, new ModalityPageManager(), getDataQuerySocket),
		props: {
			id: props.windowId,
			viewScale,
			fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.CPPOPT_PLOT, fileStartDate, fileEndDate),
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3CPPOptPlot(node, config, pageManager, callbacks)
		},
		extractJSON(config) {
			return {
				viewDuration,
				playbackSpeed: config.playbackSpeed,
				abpModality: config.abpModality,
				icpModality: config.icpModality,
			}
		},
	})

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [hotkeyActions.PREVIOUS_PAGE, hotkeyActions.NEXT_PAGE, hotkeyActions.PLAY_PAUSE]
    })

    useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
            viewDuration,
            abpModality: d3Controller?.config.abpModality,
            icpModality: d3Controller?.config.icpModality
        },
    })

    return (
        <div ref={container} style={{ height: "100%", position: "relative" }} />
    )
}