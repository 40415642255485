import { ScaleBand, ScaleLinear, scaleBand, scaleLinear } from "d3"
import { EEGMontageReactCallbacks } from "../../../../Types/ReactCallbacks"
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"
import { Offset } from "../../../../Types/Offset"
import { EEGDisplayConfig } from "../../../../Types/EEG"
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization"
import { EEGMontageConfigurationBuilder } from "./D3EEGMontageConfigurationBuilder"
import { EEGMontageRenderer } from "./EEGMontageRenderer"
import { EEGMontageConfig } from "../../../../Types/EEGMontageConfig"
import { EEGTimeSeriesPageManager } from "../../../../Data/EEGTimeSeriesPageManager"
import { EEGPage } from "../../../../Data/EEGPage"
import { TraceDataConfig } from "../../../../Types/Trace"
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants"
import { ModalityDataSource } from "../../../../Types/ModalityDataSource"
import { DataSource } from "../../../../Types/DataSource"

export class D3EEGMontage extends D3TimeBasedVisualization<EEGMontageConfig, EEGMontageReactCallbacks, EEGMontageRenderer, EEGTimeSeriesPageManager> {
	public graphBoundingBox: MarginedBoundingBox
	public margins: Offset = { top: 30, left: LEFT_MARGIN, bottom: 90, right: RIGHT_MARGIN }
	public overlayBoundingBox: MarginedBoundingBox
	public overlayMargins = { top: 0, left: 0, bottom: 0, right: 0 }
	public eegConfig: EEGDisplayConfig
    public channelScale: ScaleBand<string>
	public sensitivityScaleVoltsToPixels: ScaleLinear<any, any, any> = scaleLinear()

	constructor(root: HTMLDivElement, config: EEGMontageConfig, pageManager: EEGTimeSeriesPageManager, reactCallbacks: EEGMontageReactCallbacks) {
		super(root, config, pageManager, reactCallbacks)
		this.graphBoundingBox = new MarginedBoundingBox(config.dimensions, this.margins)
		this.overlayBoundingBox = new MarginedBoundingBox(config.dimensions, this.overlayMargins)
		this.eegConfig = new EEGDisplayConfig(config.montage, config.LFF, config.HFF, config.notch, config.sensitivityMicroVolts, config.montageMapping)

        this.channelScale = scaleBand()
			.domain(this.eegConfig.channelLabels)
			.range([0, this.graphBoundingBox.height])

		this.mount(new EEGMontageRenderer(this, new EEGMontageConfigurationBuilder(this), "d3-eeg-montage"))
	}

	// PUBLIC

	getLastHoveredDate = () => this.renderer?.getLastHoveredDate()

	getChannels = () => this.eegConfig.channels

	getOverlay = () => this.renderer?.overlay
	
    public getVisibleTraces(): TraceDataConfig[] {
        return this.getChannels()
			.filter(channel => channel !== "EEG_Ref")
			.map((channel, index) => ({ 
				id: channel,
				name: channel, 
				dataKey: channel, 
				rawDataKey: channel,
				dataSource: DataSource.CURRENT_PATIENT,
				color: "#000000", 
				units: "uV", 
				isCompositePart: true, 
				compositeIndex: index,
			})
		)
    }

	// PROTECTED

	protected renderPage = (page: EEGPage) => {
		this.renderer?.eegCanvas?.renderPage(page)
	}

	protected getModalityDataSources(): ModalityDataSource[] {
		const dataSources = []

		if (this.eegConfig?.eegModality) {
			dataSources.push({
				modality: this.eegConfig?.eegModality, 
				dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number,
			})
		}

		return dataSources
	}

	protected updateDerivedState = () => {
        this.graphBoundingBox.setDimensions(this.config.dimensions)
		this.overlayBoundingBox.setDimensions({width: this.graphBoundingBox.width, height: this.graphBoundingBox.height})
        this.config.viewScale.range([0, this.graphBoundingBox.width])
        this.eegConfig = new EEGDisplayConfig(this.config.montage, this.config.LFF, this.config.HFF, this.config.notch, this.config.sensitivityMicroVolts, this.config.montageMapping)
        
		this.channelScale
            .domain(this.eegConfig.channelDataKeys)
            .range([0, this.graphBoundingBox.height])

		// - devicePixelRatio is the ratio between physical pixels on the device and pixels as defined in CSS.
		// This is a rough estimate

		this.sensitivityScaleVoltsToPixels
			.domain([-this.config.sensitivityMicroVolts * 1e6, this.config.sensitivityMicroVolts * 1e6])
			.range([-4054, 4054])
    }
}
