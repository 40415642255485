import { ScaleTime, ScaleLinear, ScaleBand } from "d3"
import { DataSource } from "./DataSource"
import { ColorSpectrum } from "./ColorSpectrum"
import { OnDemandAnalysis } from "./AnalysisDetails"
import { ColorRGB } from "./Color"

type BaseTraceConfigJSON = {
	id: string
	dataKey: string
	dataSource: DataSource
	name: string
	units: string
	rawDataKey: string
	onDemandAnalysis?: OnDemandAnalysis
}

// Some traces are part of composite data types. EEG, CPPOPT, etc.
// So, we need to provide the index of the composite data that they belong to.
export type CompositePartTraceConfigJSON = BaseTraceConfigJSON & {
	isCompositePart: true
	compositeIndex: number
}

// If the trace is not a composite part, then it does not have a composite index.
export type NonCompositePartTraceConfig = BaseTraceConfigJSON & {
	isCompositePart: false
}

export type TraceDataConfig = CompositePartTraceConfigJSON | NonCompositePartTraceConfig

export type SolidColorTraceDataConfig = TraceDataConfig & WithSolidColor

export type TraceConfigJSON = LineTraceConfigJSON | HeatmapTraceConfigJSON | DeltaRegionJSON

export type TraceConfigJSONWithDataObjectId = (TraceConfigJSON & { dataObjectId: number }) | DeltaRegionWithDataObjectId

type DeltaRegionWithDataObjectId = DeltaRegion & {
	first: BaseTraceConfig & TraceDataConfig & { dataObjectId: number }
	second: BaseTraceConfig & TraceDataConfig & { dataObjectId: number }
}

export type TraceConfig = LineTraceConfig | HeatmapTraceConfig | DeltaRegion

export type BaseTraceConfig = {
	graphId: string
	xScale: ScaleTime<any, any, any>
}

export type LineTraceConfig = LineTraceConfigJSON & BaseTraceConfig & {
	yScale: ScaleLinear<any, any, any>
}

export type HeatmapTraceConfig = HeatmapTraceConfigJSON & BaseTraceConfig & {
	yScale: ScaleBand<any>
}

export type PageRectangle = {
	x: number
	y: number
	width: number
	height: number
}

export enum RenderStrategy {
	LINE = "line",
	HEATMAP = "heatmap",
	IMAGE = "image",
	DELTA = "delta"
}

export type TraceOption = {
    label: string
    value: BaseTraceConfigJSON
    highlight: boolean
}

export type DeltaRegionJSON = {
	id: string
	renderStrategy: RenderStrategy.DELTA
    first: BaseTraceConfig & TraceDataConfig
	second: BaseTraceConfig & TraceDataConfig
	color: ColorRGB
}

export type DeltaRegion = DeltaRegionJSON & BaseTraceConfig & {
	yScale: ScaleLinear<any, any, any>
}

export type LineTraceConfigJSON = TraceDataConfig & WithSolidColor & {
	renderStrategy: RenderStrategy.LINE
	color: string
}

export type HeatmapTraceConfigJSON = TraceDataConfig & WithColorSpectrum & {
	renderStrategy: RenderStrategy.HEATMAP
	lowerBound: number
	upperBound: number
}

export const getHeatmapTraceId = (config: HeatmapTraceConfigJSON) => config.id

export type WithSolidColor = { color: string }

export type WithColorSpectrum = { colorSpectrum: ColorSpectrum }

export const getFullTraceDataConfigs = (trace: TraceConfigJSON | TraceConfigJSONWithDataObjectId): (BaseTraceConfigJSON & TraceDataConfig)[] => {
	// Because of delta regions (which have 2 trace configurations) we need to be able to cleanly 
	// get the information we need to request data from the backend. This happens pretty often.

	switch (trace.renderStrategy) {
		case RenderStrategy.DELTA:
			return [trace.first, trace.second]
		default:
			return [trace]
	}
}
