import React from "react"

// This is only needed for creating sub menus. We have to know where the next submenu starts in the DOM tree, otherwise
// clicking "outside" a menu is ambiguous.
export const MobergSubMenuBoundary: React.FC = ({ children }) => {
	return (
		<div onMouseDown={event => event.stopPropagation()} onClick={event => event.stopPropagation()}>
			{children}
		</div>
	)
}
