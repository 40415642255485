import { isGenericAnalytic } from "../../../Types/AnalysisDetails";
import { ModalityGraphConfigJSON } from "../../../Types/Graph";
import { getFullTraceDataConfigs } from "../../../Types/Trace";

export function getVisibleGraphs<T extends ModalityGraphConfigJSON>(graphs: T[], patientModalities: string[], hideEmptyGraphs: boolean) {
    if (!hideEmptyGraphs) {
        return graphs
    }

    return graphs.filter(graph => graph.traces.flatMap(getFullTraceDataConfigs).some(trace => 
        patientModalities.includes(trace.rawDataKey) || !isGenericAnalytic(trace.onDemandAnalysis?.analytic)
    ))
}