import { useState, useEffect } from "react"
import * as MdIcons from 'react-icons/md';

// intrusive design, updated DOM directly, used in headers
const IntrusiveCheckboxButton = ({state, action, checkboxStyle={}, checkboxSize, ...props})=> {
    const [buttonId,] = useState(props.id ? props.id : `IntrusiveCheckboxButton-${Math.round(Math.random()*1e7).toString()}`)
    const [buttonState, setButtonState] = useState(Boolean(state))

    useEffect(() => {
        if (buttonState !== state) {
            const buttonContainer = document.getElementById(buttonId)
            if (buttonContainer) { // makesure DOM has rendered it
                buttonContainer.children[0].style.display = state ? "block" : "none"
                buttonContainer.children[1].style.display = state ? "none" : "block"
                setButtonState(state)

            }
        }

    }, [action, buttonId, buttonState, state])
    const cursor = props.preview ? "default" : "pointer"
   
    return (
        <div id={buttonId}>
            <MdIcons.MdCheckBox size={checkboxSize ? checkboxSize : 22} color={props.checkboxStyle?.color ? props.checkboxStyle.color : props.preview ? "#B6B6B6" : "#207DEA"}
                style={{display: state ? "block" : "none", cursor: cursor, ...checkboxStyle}}
                onClick={!action ? null : () => action(false)}
                {...props}
            />
            <MdIcons.MdCheckBoxOutlineBlank size={checkboxSize ? checkboxSize : 26} color={props.checkboxStyle?.color ? props.checkboxStyle.color : "#B6B6B6"}
                style={{display: state ? "none" : "block", cursor: cursor, ...checkboxStyle}}
                onClick={!action ? null : () => action(true)}
                {...props}
            />
        </div>
    )
}

// non-intrusive design, updated DOM directly, used in headers
const CheckboxButton = ({state, action, checkboxSize, ...props})=> {   
    const cursor = props.preview ? "default" : "pointer"
    return (
        state 
        ? (<MdIcons.MdCheckBox size={checkboxSize ? checkboxSize : 22} color={props.checkboxStyle?.color ? props.checkboxStyle.color : props.preview ? "#B6B6B6" : "#207DEA"}
            style={{cursor: cursor}}
            onMouseDown={!action ? null : () => action(false)} {...props}/>)
        : (<MdIcons.MdCheckBoxOutlineBlank size={checkboxSize ? checkboxSize : 22} color={props.checkboxStyle?.color ? props.checkboxStyle.color : "#B6B6B6"}
            style={{cursor: cursor}}
            onMouseDown={!action ? null : () => action(true)} {...props}/>)
    )
}

export { IntrusiveCheckboxButton, CheckboxButton };