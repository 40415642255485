import React from "react"
import { BaseVisualizationConfigureModal } from "../../../Modals/BaseVisualizationConfigureModal"
import { MobergColumn, MobergDropdown } from "../../../../../../../../Moberg"
import { CPPOPT_WINDOW_TIME_PRESETS } from "../../../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { cppOptPlotConfigsAtom } from "../../../../Atoms/CPPOpt"
import { useEditWindow } from "../../../Modals/useEditWindow"
import { CPPOptPlotJSON } from "../../../../Types/CPPOptPlot"
import { useRecoilValue } from "recoil"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"

type CPPOptPlotConfigureModalProps = {
	windowId: string
	layoutId: string
}

export const CPPOptPlotConfigureModal = (props: CPPOptPlotConfigureModalProps) => {
	const atom = cppOptPlotConfigsAtom({windowId: props.windowId, layoutId: props.layoutId})
	const { patientModalities } = useRecoilValue(currentPatientFileInfoAtom)
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<CPPOptPlotJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })
	const sortedModalities = [...patientModalities].sort()
    
	return (
		<BaseVisualizationConfigureModal layoutId={props.layoutId} windowId={props.windowId} title={"Configure CPPOpt"} saveChanges={saveChanges}>
			<div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<MobergColumn gap="32px" verticalAlign="center" expand={true}>
					<MobergColumn gap="16px">
						<MobergDropdown
							label= "Initial Window Size"
							selectedValue={editedConfig?.viewDuration}
							onChange={value => updateProperty("viewDuration", parseInt(value as string))}
							options={CPPOPT_WINDOW_TIME_PRESETS
								.filter(preset => preset.label !== "Page Size")
								.map(preset => ({ label: preset.label, value: preset.time }))}
						/>

						<MobergDropdown
							label="ABP Modality"
							selectedValue={editedConfig?.abpModality}
							onChange={modality => updateProperty("abpModality", modality)}
							options={sortedModalities.map(modality => ({ label: modality, value: modality }))}
						/>

						<MobergDropdown
							label="ICP Modality"
							selectedValue={editedConfig?.icpModality}
							onChange={modality => updateProperty("icpModality", modality)}
							options={sortedModalities.map(modality => ({ label: modality, value: modality }))}
						/>
					</MobergColumn>
				</MobergColumn>
			</div>
		</BaseVisualizationConfigureModal>
	)
}
