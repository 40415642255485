import { interpolateGreens, interpolateGreys, interpolateInferno, interpolateRdBu, interpolateRdYlGn, interpolateReds, interpolateViridis } from "d3"

export enum ColorSpectrum {
    INFERNO = "inferno",
	VIRIDIS = "viridis",
	GRAYS = "grays",
    GREENS = "greens",
    REDS = "reds",
    RED_BLUE = "red_blue",
    RED_YELLOW_GREEN = "red_yellow_green"
}

export const getSpectrumInterpolator = (spectrum: ColorSpectrum) => {
	switch (spectrum) {
        case ColorSpectrum.INFERNO: return interpolateInferno
		case ColorSpectrum.VIRIDIS: return interpolateViridis
		case ColorSpectrum.GRAYS: return interpolateGreys
        case ColorSpectrum.GREENS: return interpolateGreens
        case ColorSpectrum.REDS: return interpolateReds
        case ColorSpectrum.RED_BLUE: return interpolateRdBu
        case ColorSpectrum.RED_YELLOW_GREEN: return interpolateRdYlGn

		default: return interpolateViridis
	}
}
