import { useContext } from "react"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import React from "react"

type TabCountProps = {
	tab: string
}

export 	const TabCount: React.FC<TabCountProps> = ({ tab }) => {
    const { activeSourceTraces, activeAnalyticTraces, activeDeltaRegions } = useContext(ConfigureWindowModalContext)
    let count = 0

    switch(tab) {
        case "Source data": count = activeSourceTraces.length; break
        case "Analytics": count = activeAnalyticTraces.length; break
        case "Delta regions": count = activeDeltaRegions.length; break
    }

    return <div><span style={{ fontSize: "0.9rem" }}>({count})</span></div>
}