import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as MdIcons from 'react-icons/md'
import Calendar from './Calendar'
import { useModalProvider } from '../../../../Providers/ModalProvider'
import { MobergButton } from '../../../../Components/MobergButton/MobergButton'
import { MobergButtonVariant } from '../../../../Components/MobergButton/MobergButton'
import { MobergButtonShape } from '../../../../Components/MobergButton/MobergButton'
import { MobergIconSize } from '../../../../Components/MobergIcon/MobergIcon'
import { MobergTheme } from '../../../../Components/MobergThemes/MobergColors'
import { MobergFontSize } from '../../../../Components/MobergFont/MobergFont'
import { MobergAnimationTiming, MobergAnimationCurve, MobergAnimationTimingMs } from '../../../../Components/MobergAnimation/MobergAnimation'

function TimeSelector({ currentTime, currentEndTime, minTime, maxTime, onChange, calendar = false, interval = false, selectorStyle = {}, showX = true, orientation = "vertical", autoSave = false, enableResetChanges = false, timeZone = "" }) {
	const months = {
		January: 1,
		February: 2,
		March: 3,
		April: 4,
		May: 5,
		June: 6,
		July: 7,
		August: 8,
		September: 9,
		October: 10,
		November: 11,
		December: 12
	}

	const { close } = useModalProvider()

	const [unchangedStartDate, setUnchangedStartDate] = useState(currentTime)
	const [unchangedEndDate, setUnchangedEndDate] = useState(currentEndTime)
	const [madeChanges, setMadeChanges] = useState(false)

	function getDaysInMonth(month, year) {
		const days = new Date(year, month + 1, 0).getDate()

		return days
	}

	function getYears() {
		const startYear = new Date(minTime).getFullYear()
		const endYear = new Date(maxTime).getFullYear()
		const yearsInRange = []

		for (let year = startYear; year <= endYear; year++) {
			yearsInRange.push(year)
		}

		return yearsInRange
	}

	const [currentDate, setCurrentDate] = useState(new Date(currentTime))
	const [currentEndDate, setCurrentEndDate] = useState(new Date(currentEndTime))
	const [selectedOption, setSelectedOption] = useState('start')
	const [showCalendar, setShowCalendar] = useState(true)
	const hour12 = selectedOption === 'start' ? currentDate.getHours() % 12 : currentEndDate.getHours() % 12 || 12
	const [isPM, setIsPM] = useState(selectedOption === 'start' ? new Date(currentTime).getHours() >= 12 : new Date(currentEndTime).getHours() >= 12)

	const handleOptionClick = option => {
		setSelectedOption(option)
	}

	function toggleAMPM(setting) {
		const date = selectedOption === 'start' ? currentDate : currentEndDate

		const hours = date.getHours()

		if (setting === 'AM') {
			if (hours < 12) return
			setIsPM(false)
			date.setHours(hours - 12)
		} else {
			if (hours >= 12) return
			setIsPM(true)
			date.setHours(hours + 12)
		}

		if (selectedOption === 'start') {
			setCurrentDate(new Date(date.getTime()))
		} else {
			setCurrentEndDate(new Date(date.getTime()))
		}
	}

	function isToggleAMPMValid(toggleFormat) {
		const date = selectedOption === 'start' ? currentDate : currentEndDate
		const toggledDate = new Date(date.getTime())
		toggledDate.setHours(toggledDate.getHours() >= 12 ? toggledDate.getHours() - 12 : toggledDate.getHours() + 12)
		const startDate = new Date(currentDate)
		const endDate = new Date(currentEndDate)
		let valid

		if (toggleFormat === 'AM') {
			if (interval && selectedOption === 'end') {
				valid = toggledDate.getTime() >= startDate.getTime()
			} else {
				valid = true
			}
			// Check if toggling to AM is valid
			return toggledDate.getTime() >= new Date(minTime).getTime() && valid
		} else if (toggleFormat === 'PM') {
			if (interval && selectedOption === 'start') {
				valid = toggledDate.getTime() <= endDate.getTime()
			} else {
				valid = true
			}
			// Check if toggling to PM is valid
			return toggledDate.getTime() <= new Date(maxTime).getTime() && valid
		}

		return false
	}

	function isMonthInRange(targetMonth, targetYear = null) {
		const startDate = new Date(minTime)
		const endDate = new Date(maxTime)

		if (isNaN(startDate) || isNaN(endDate)) {
			throw new Error('Invalid date format')
		}

		let startYear = startDate.getFullYear()
		let startMonth = startDate.getMonth() + 1

		let endYear = endDate.getFullYear()
		let endMonth = endDate.getMonth() + 1

		let yearsToCheck = targetYear ? [targetYear] : Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index)

		for (let year of yearsToCheck) {
			let loopStartMonth = year === startYear ? startMonth : 1
			let loopEndMonth = year === endYear ? endMonth : 12

			if (targetMonth >= loopStartMonth && targetMonth <= loopEndMonth) {
				return true
			}
		}

		return false
	}

	function validateHours(date) {
		if (date.getTime() > new Date(maxTime).getTime()) {
			// If the date's time is beyond the maximum allowed time, set it to the maximum time.
			const maxDate = new Date(maxTime)
			date.setHours(maxDate.getHours(), maxDate.getMinutes(), 0, 0)
		} else if (date.getTime() < new Date(minTime).getTime()) {
			const minDate = new Date(minTime)
			date.setHours(minDate.getHours(), minDate.getMinutes(), 0, 0)
		}
		date.setSeconds(0, 0)
	}

	function updateDate(date, type, value) {
		switch (type) {
			case 'year':
				date.setFullYear(parseInt(value))
				break
			case 'month':
				let days
				if (getDaysInMonth(value, date.getFullYear()) < getDaysInMonth(date.getMonth(), date.getFullYear())) {
					days = 1
					date.setMonth(parseInt(value - 1), days)
				} else {
					date.setMonth(parseInt(value - 1))
				}

				break
			case 'day':
				date.setDate(parseInt(value))
				validateHours(date)
				break
			case 'hour':
				date.setHours(parseInt(value))
				break
			case 'minute':
				date.setMinutes(parseInt(value))
				break
			default:
				break
		}
	}

	function updateTime(validate, type, value, month, year) {
		let date = selectedOption === 'start' ? new Date(currentDate.getTime()) : new Date(currentEndDate.getTime())

		if (date.getTime() < new Date(minTime).getTime()) {
			date = new Date(minTime)
		} else if (date.getTime() > new Date(maxTime).getTime()) {
			date = new Date(maxTime)
		}

		// Validating the Calendar days
		if (validate) {
			if (month !== undefined) {
				let days
				if (getDaysInMonth(month + 1, date.getFullYear()) < getDaysInMonth(date.getMonth(), date.getFullYear())) {
					days = 1
					date.setMonth(month, days)
				} else {
					date.setMonth(month)
				}
			}

			if (year !== undefined) {
				date.setFullYear(year)
			}
		}

		// Setting days in a new month
		if (!validate && year !== undefined) {
			updateDate(date, 'year', year)
		}

		if (!validate && month !== undefined) {
			updateDate(date, 'month', month + 1)
		}

		updateDate(date, type, value)

		if (validate) {
			const minDateTime = new Date(minTime)
			const maxDateTime = new Date(maxTime)

			// Set the hours, minutes, and seconds to the same values for all dates
			const tempDate = new Date(date.getTime())
			const tempMinDate = new Date(currentDate.getTime())
			const tempMaxDate = new Date(currentEndDate.getTime())

			if (type !== 'hour' && type !== 'minute') {
				minDateTime.setHours(0, 0, 0, 0)
				maxDateTime.setHours(0, 0, 0, 0)
				tempDate.setHours(0, 0, 0, 0)
				tempMinDate.setHours(0, 0, 0, 0)
				tempMaxDate.setHours(0, 0, 0, 0)
			}


			const selectedDateTime = tempDate.getTime()

			if (interval) {
				if (selectedOption === 'start') {
					return selectedDateTime < minDateTime.getTime() || selectedDateTime > maxDateTime.getTime()
				} else {
					return selectedDateTime < minDateTime.getTime() || selectedDateTime > maxDateTime.getTime()
				}
			} else {
				// For single date mode, disable dates outside the allowed range
				return selectedDateTime < minDateTime.getTime() || selectedDateTime > maxDateTime.getTime()
			}
		} else {
			if (date.getTime() < new Date(minTime).getTime()) {
				// If the updated month is outside the minimum range, set the date to the earliest valid day in range.
				date = new Date(minTime)
			} else if (date.getTime() > new Date(maxTime).getTime()) {
				// If the updated month is outside the maximum range, set the date to the latest valid day in range.
				date = new Date(maxTime)
			}

			validateHours(date)
			if (selectedOption === 'start') {
				setCurrentDate(date)
			} else {
				setCurrentEndDate(date)
			}
		}
	}

	const [errorMessage, setErrorMessage] = useState(undefined)

	function saveChanges(closeModal = false) {
		if (interval) {
			if (orientation === "editAnnotation") {
				onChange(currentDate.getTime(), currentEndDate.getTime())
			} else {
				if (currentDate.getTime() > currentEndDate.getTime()) {
					setErrorMessage("Start time cannot be greater than the end time")
					return
				} else if (currentDate.getTime() === currentEndDate.getTime()) {
					setErrorMessage("Start time cannot be equal to the end time")
					return
				} else {
					setErrorMessage(undefined)
					onChange(currentDate.getTime(), currentEndDate.getTime())
				}
			}
		} else {
			onChange(currentDate.getTime())
		}

		setUnchangedStartDate(currentDate.getTime())
		setUnchangedEndDate(currentEndDate.getTime())
		setMadeChanges(false)
		if (closeModal) {
			close()
		}
	}

	useEffect(() => {
		if (selectedOption === 'start') {
			setIsPM(currentDate.getHours() >= 12)
		} else {
			setIsPM(currentEndDate.getHours() >= 12)
		}

		if (unchangedStartDate !== currentDate.getTime() || unchangedEndDate !== currentEndDate.getTime()) {
			setMadeChanges(true)
		} else {
			setMadeChanges(false)
		}
	}, [currentDate, currentEndDate, selectedOption])

	function formatDateToCustomString(date) {
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const day = String(date.getDate()).padStart(2, '0')
		const year = date.getFullYear()
		let hours = date.getHours()
		const minutes = String(date.getMinutes()).padStart(2, '0')
		const ampm = hours >= 12 ? 'PM' : 'AM'

		// Convert to 12-hour format
		if (hours > 12) {
			hours -= 12
		} else if (hours === 0) {
			hours = 12
		}

		return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`
	}

	useEffect(() => {
		if (autoSave) {
			saveChanges()
		}
	}, [autoSave, currentDate, currentEndDate])

	if (calendar) {
		if (orientation === "vertical") {
			return (
				<>
					<TimeSelectorContainer style={{ background: "#FFFFFF", ...selectorStyle }}>
						{showX &&
							<div style={{ position: 'absolute', right: 0, top: 0, zIndex: 2, display: `${showX ? 'block' : 'none'}` }}>
								<MobergButton
									variant={MobergButtonVariant.CLEAR}
									onClick={close}
									shape={MobergButtonShape.SQUARE}
								>
									<MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
								</MobergButton>
							</div>
						}

						{interval ? (
							<Selector>
								<Option className={selectedOption === 'start' ? 'selected' : ''} onClick={() => handleOptionClick('start')}>
									Start
									<TimeText selected={selectedOption === 'start'}>{formatDateToCustomString(currentDate)}</TimeText>
								</Option>
								<Option className={selectedOption === 'end' ? 'selected' : ''} onClick={() => handleOptionClick('end')}>
									End
									<TimeText selected={selectedOption === 'end'}>{formatDateToCustomString(currentEndDate)}</TimeText>
								</Option>
								<Slider style={{ left: selectedOption === 'start' ? '5px' : '197px' }} />
							</Selector>
						) : null}

						<div style={{ display: 'flex', width: '100%', alignItems: 'center', marginLeft: '10px', marginTop: interval ? '0px' : '10px' }}>
							<span>
								<MdIcons.MdCalendarMonth size={24} />
							</span>

							<TimeSelect onChange={e => updateTime(false, 'month', e.target.value)}>
								{Object.keys(months).map(month => {
									const date = selectedOption === 'start' ? currentDate : currentEndDate
									return (
										<option
											id={`${date?.getMonth()}-${months[month] === date?.getMonth()}`}
											value={months[month]}
											selected={months[month] - 1 === date?.getMonth()}
											disabled={!isMonthInRange(months[month], date.getFullYear())}
											style={{ color: !isMonthInRange(months[month], date.getFullYear()) ? '#c7bebd' : 'unset', padding: '5px' }}
										>
											{month}
										</option>
									)
								})}
							</TimeSelect>
							/
							<TimeSelect onChange={e => updateTime(false, 'day', e.target.value)}>
								{Array.from(
									{
										length: getDaysInMonth(
											selectedOption === 'start' ? currentDate.getMonth() : currentEndDate.getMonth(),
											selectedOption === 'start' ? currentDate.getFullYear() : currentEndDate.getFullYear()
										)
									},
									(_, i) => i + 1
								).map(day => {
									const date = selectedOption === 'start' ? currentDate : currentEndDate
									return (
										<option
											id={`${updateTime(true, 'day', parseInt(day))}`}
											value={day}
											selected={day === date?.getDate()}
											disabled={updateTime(true, 'day', parseInt(day))}
											style={{ color: updateTime(true, 'day', parseInt(day)) ? '#c7bebd' : 'unset' }}
										>
											{String(day).padStart(2, '0')}
										</option>
									)
								})}
							</TimeSelect>
							/
							<TimeSelect onChange={e => updateTime(false, 'year', e.target.value)}>
								{getYears().map(year => {
									const date = selectedOption === 'start' ? currentDate : currentEndDate
									return (
										<option value={year} selected={year === date.getFullYear()}>
											{year}
										</option>
									)
								})}
							</TimeSelect>

							<MobergButton
								theme={MobergTheme.BLUE}
								variant={MobergButtonVariant.CLEAR}
								onClick={() => setShowCalendar(!showCalendar)}
								shape={MobergButtonShape.SQUARE}
								fontSize={MobergFontSize.LARGE}
							>
								{`${showCalendar ? 'Hide' : 'View'}`} Calendar
							</MobergButton>
						</div>

						<div style={{ display: 'flex', width: 'fit-content', alignItems: 'center', alignSelf: 'start' }}>
							<div style={{ display: 'flex', width: 'fit-content', alignItems: 'center' }}>
								<MdIcons.MdAccessTime size={24} />
								<TimeSelect onChange={e => updateTime(false, 'hour', e.target.value)}>
									{Array.from({ length: 12 }, (_, i) => i + 1).map(hour => {
										const valid = updateTime(true, 'hour', parseInt(isPM ? hour + 12 : hour))
										return (
											<option value={isPM ? hour + 12 : hour} selected={hour === hour12} disabled={valid} style={{ color: valid ? '#c7bebd' : 'unset' }}>
												{String(hour).padStart(2, '0')}
											</option>
										)
									})}
								</TimeSelect>
								:
								<TimeSelect onChange={e => updateTime(false, 'minute', e.target.value)}>
									{Array.from({ length: 60 }, (_, i) => i).map(minute => {
										const date = selectedOption === 'start' ? currentDate : currentEndDate
										const paddedMinute = String(minute).padStart(2, '0')
										const valid = updateTime(true, 'minute', parseInt(minute))
										return (
											<option value={minute} selected={minute === date.getMinutes()} disabled={valid} style={{ color: valid ? '#c7bebd' : 'unset' }}>
												{paddedMinute}
											</option>
										)
									})}
								</TimeSelect>
							</div>

							<div style={{ display: 'flex', alignItems: 'center' }}>
								<input
									type='radio'
									id={`AM`}
									name='time'
									value='AM'
									style={{ marginRight: '3px', cursor: !isToggleAMPMValid('AM') ? 'not-allowed' : 'pointer' }}
									checked={!isPM}
									defaultChecked={!isPM}
									onClick={() => toggleAMPM('AM')}
									disabled={!isToggleAMPMValid('AM')}
								/>
								<RadioLabel for='AM' selected={!isPM} id={`${!isPM}`}>
									AM
								</RadioLabel>
							</div>

							<div style={{ display: 'flex', alignItems: 'center' }}>
								<input
									type='radio'
									id={`PM`}
									name='time'
									value='PM'
									style={{ marginRight: '3px', cursor: !isToggleAMPMValid('PM') ? 'not-allowed' : 'pointer' }}
									checked={isPM}
									defaultChecked={isPM}
									onClick={() => toggleAMPM('PM')}
									disabled={!isToggleAMPMValid('PM')}
								/>
								<RadioLabel for='PM' selected={isPM} id={`${isPM}`}>
									PM
								</RadioLabel>
							</div>
						</div>

						<Calendar
							show={showCalendar}
							selectedOption={selectedOption}
							currentDate={currentDate}
							currentEndDate={currentEndDate}
							minTime={minTime}
							maxTime={maxTime}
							months={months}
							updateTime={updateTime}
							isMonthInRange={isMonthInRange}
							interval={interval}
						/>

						{errorMessage && (
							<ErrorMessage>
								{errorMessage}
							</ErrorMessage>
						)}

						<div style={{ display: 'flex', width: '-webkit-fill-available', justifyContent: 'space-between' }}>
							<MobergButton
								theme={MobergTheme.BLUE}
								variant={MobergButtonVariant.OUTLINE}
								onClick={() => {
									setCurrentDate(new Date(currentTime))
									setCurrentEndDate(new Date(currentEndTime))
									setErrorMessage(undefined)
								}}
								shape={MobergButtonShape.WIDE}
								fontSize={MobergFontSize.LARGE}
								disabled={!madeChanges}
							>
								Reset changes
							</MobergButton>

							{!autoSave &&
								<MobergButton
									theme={MobergTheme.BLUE}
									variant={MobergButtonVariant.FILLED}
									onClick={() => saveChanges(true)}
									shape={MobergButtonShape.WIDE}
									fontSize={MobergFontSize.LARGE}
									disabled={!madeChanges}
								>
									Save changes
								</MobergButton>
							}
						</div>
					</TimeSelectorContainer>
				</>
			)
		} else if (orientation === "editAnnotation") {
			return (
				<>
					<TimeSelectorContainer style={{ border: "none", boxShadow: "none", display: "-webkit-box", padding: "0", background: "none", ...selectorStyle, marginTop: "-10px" }}>
						<div style={{ position: 'absolute', right: 0, top: 0, zIndex: 2, display: `${showX ? 'block' : 'none'}` }}>
							<MobergButton
								variant={MobergButtonVariant.CLEAR}
								onClick={close}
								shape={MobergButtonShape.SQUARE}
							>
								<MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
							</MobergButton>
						</div>
						<div style={{ marginTop: "30px", marginRight: "40px", width: "325px" }}>
							{interval ? (
								<Selector style={{ width: "380px", marginLeft: "-30px", backgroundColor: "rgba(32, 125, 234, 0.5)" }}>
									<Option className={selectedOption === 'start' ? 'selected' : ''} onClick={() => handleOptionClick('start')}>
										Start
										<TimeText selected={selectedOption === 'start'}>{formatDateToCustomString(currentDate)}</TimeText>
									</Option>
									<Option className={selectedOption === 'end' ? 'selected' : ''} onClick={() => handleOptionClick('end')}>
										End
										<TimeText selected={selectedOption === 'end'}>{formatDateToCustomString(currentEndDate)}</TimeText>
									</Option>
									<Slider style={{ left: selectedOption === 'start' ? '5px' : '185px' }} />
								</Selector>
							) : null}

							<div style={{ display: 'flex', width: '100%', alignItems: 'center', marginLeft: '10px', marginTop: interval ? '25px' : '-55px' }}>
								<MdIcons.MdCalendarMonth size={24} />
								<TimeSelect onChange={e => updateTime(false, 'month', e.target.value)} style={{ padding: "4px 4px" }}>
									{Object.keys(months).map(month => {
										const date = selectedOption === 'start' ? currentDate : currentEndDate
										return (
											<option
												id={`${date?.getMonth()}-${months[month] === date?.getMonth()}`}
												value={months[month]}
												selected={months[month] - 1 === date?.getMonth()}
												disabled={!isMonthInRange(months[month], date.getFullYear())}
												style={{ color: !isMonthInRange(months[month], date.getFullYear()) ? '#c7bebd' : 'unset' }}
											>
												{month}
											</option>
										)
									})}
								</TimeSelect>
								/
								<TimeSelect onChange={e => updateTime(false, 'day', e.target.value)} style={{ padding: "4px 4px" }}>
									{Array.from(
										{
											length: getDaysInMonth(
												selectedOption === 'start' ? currentDate.getMonth() : currentEndDate.getMonth(),
												selectedOption === 'start' ? currentDate.getFullYear() : currentEndDate.getFullYear()
											)
										},
										(_, i) => i + 1
									).map(day => {
										const date = selectedOption === 'start' ? currentDate : currentEndDate
										return (
											<option
												id={`${updateTime(true, 'day', parseInt(day))}`}
												value={day}
												selected={day === date?.getDate()}
												disabled={updateTime(true, 'day', parseInt(day))}
												style={{ color: updateTime(true, 'day', parseInt(day)) ? '#c7bebd' : 'unset' }}
											>
												{String(day).padStart(2, '0')}
											</option>
										)
									})}
								</TimeSelect>
								/
								<TimeSelect onChange={e => updateTime(false, 'year', e.target.value)} style={{ padding: "4px 4px" }}>
									{getYears().map(year => {
										const date = selectedOption === 'start' ? currentDate : currentEndDate
										return (
											<option value={year} selected={year === date.getFullYear()}>
												{year}
											</option>
										)
									})}
								</TimeSelect>
							</div>

							<div style={{ display: 'flex', width: 'fit-content', alignItems: 'center', alignSelf: 'start', marginLeft: "5px", marginTop: "10px" }}>
								<div style={{ display: 'flex', width: 'fit-content', alignItems: 'center' }}>
									<MdIcons.MdAccessTime size={24} />
									<TimeSelect onChange={e => updateTime(false, 'hour', e.target.value)} style={{ padding: "4px 4px" }}>
										{Array.from({ length: 12 }, (_, i) => i + 1).map(hour => {
											const valid = updateTime(true, 'hour', parseInt(isPM ? hour + 12 : hour))
											return (
												<option value={isPM ? hour + 12 : hour} selected={hour === hour12} disabled={valid} style={{ color: valid ? '#c7bebd' : 'unset' }}>
													{String(hour).padStart(2, '0')}
												</option>
											)
										})}
									</TimeSelect>
									:
									<TimeSelect onChange={e => updateTime(false, 'minute', e.target.value)} style={{ padding: "4px 4px" }}>
										{Array.from({ length: 60 }, (_, i) => i).map(minute => {
											const date = selectedOption === 'start' ? currentDate : currentEndDate
											const paddedMinute = String(minute).padStart(2, '0')
											const valid = updateTime(true, 'minute', parseInt(minute))
											return (
												<option value={minute} selected={minute === date.getMinutes()} disabled={valid} style={{ color: valid ? '#c7bebd' : 'unset' }}>
													{paddedMinute}
												</option>
											)
										})}
									</TimeSelect>
								</div>

								<div style={{ display: 'flex', alignItems: 'center', marginTop: "5px", whiteSpace: "nowrap" }}>
									<input
										type='radio'
										id={`AM`}
										name='time'
										value='AM'
										style={{ marginRight: '3px', cursor: !isToggleAMPMValid('AM') ? 'not-allowed' : 'pointer' }}
										checked={!isPM}
										defaultChecked={!isPM}
										onClick={() => toggleAMPM('AM')}
										disabled={!isToggleAMPMValid('AM')}
									/>
									<RadioLabel for='AM' selected={!isPM} id={`${!isPM}`}>
										AM ({timeZone})
									</RadioLabel>
								</div>

								<div style={{ display: 'flex', alignItems: 'center', marginTop: "5px", whiteSpace: "nowrap" }}>
									<input
										type='radio'
										id={`PM`}
										name='time'
										value='PM'
										style={{ marginRight: '3px', cursor: !isToggleAMPMValid('PM') ? 'not-allowed' : 'pointer' }}
										checked={isPM}
										defaultChecked={isPM}
										onClick={() => toggleAMPM('PM')}
										disabled={!isToggleAMPMValid('PM')}
									/>
									<RadioLabel for='PM' selected={isPM} id={`${isPM}`}>
										PM ({timeZone})
									</RadioLabel>
								</div>
							</div>

							<div style={{ display: 'flex', width: '-webkit-fill-available', justifyContent: 'space-between', position: 'relative', height: "40px" }}>
								<MobergButton
									theme={MobergTheme.BLUE}
									variant={MobergButtonVariant.OUTLINE}
									onClick={() => {
										setCurrentDate(new Date(currentTime))
										setCurrentEndDate(new Date(currentEndTime))
										setErrorMessage(undefined)
									}}
									shape={MobergButtonShape.WIDE}
									fontSize={MobergFontSize.LARGE}
									disabled={!(enableResetChanges || madeChanges)}
								>
									Reset changes
								</MobergButton>

								{!autoSave &&
									<MobergButton
										theme={MobergTheme.BLUE}
										variant={MobergButtonVariant.FILLED}
										onClick={() => saveChanges(true)}
										shape={MobergButtonShape.WIDE}
										fontSize={MobergFontSize.LARGE}
										disabled={!madeChanges}
									>
										Save changes
									</MobergButton>
								}
							</div>

						</div>

						<Calendar
							show={showCalendar}
							selectedOption={selectedOption}
							currentDate={currentDate}
							currentEndDate={currentEndDate}
							minTime={minTime}
							maxTime={maxTime}
							months={months}
							updateTime={updateTime}
							isMonthInRange={isMonthInRange}
							interval={interval}
						/>

					</TimeSelectorContainer>
				</>
			)
		}

	} else {
		return <TimeSelectorInput type='datetime-local' id='Time-Selector' value={currentTime} min={minTime} max={maxTime} required onChange={e => onChange(e.target.value)} />
	}
}

export default TimeSelector

const TimeSelectorContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: white;
	border-radius: 5px;
	box-shadow: 0px 6.515929698944092px 32.579647064208984px 0px rgba(9, 44, 76, 0.16);
	padding: 40px 10px 20px 10px;

	div {
		padding: 5px;
	}
`

const TimeSelectorInput = styled.input`
	&::-webkit-clear-button {
		display: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
`

const TimeSelect = styled.select`
	margin: 0px 5px 0px 5px;
	text-align: center;
	border-radius: 6px;
	border: 1px solid #aeb7c6;
	padding: 5px;
`

const RadioLabel = styled.label`
	margin-right: 3px;
	color: ${({ selected }) => (selected ? '#207DEA' : '#656a73')};
	font-family: Source Sans Pro;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	cursor: pointer;
`

const Selector = styled.div`
	width: 100%;
	height: 60px;
	border-radius: 30px;
	background-color: #aeb7c6;
	display: flex;
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
	margin-top: 20px;
`

const Option = styled.div`
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	border-radius: 25px;
	cursor: pointer;
	flex-direction: column;

	&.selected {
		color: #293241;
		z-index: 1;
	}
`

const TimeText = styled.p`
	color: ${({ selected }) => (selected ? '#207dea' : 'rgba(0, 0, 0, 0.4)')};
	font-weight: ${({ selected }) => (selected ? '600' : 'normal')}; ;
`

const Slider = styled.div`
	position: absolute;
	width: 50%; /* Set the width of the slider */
	height: 51px;
	border-radius: 27.5px;
	background-color: #fff;
	transition: left ${MobergAnimationTiming.VERY_FAST} ${MobergAnimationCurve.EASE_IN};
	left: ${({ selectedOption }) => (selectedOption === 'start' ? '5px' : '75px')}; /* Adjust left position */
	top: 5px; /* Move the slider up */
`

const ErrorMessage = styled.div`
	color: #E54E58;
	font-size: 16px;
	font-family: Montserrat;
	font-weight: 600;
`