import { ScaleBand, ScaleLinear, scaleBand, scaleLinear } from "d3";
import { EEGDisplayConfig } from "../../../../Types/EEG";
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox";
import { SdDetectionReactCallbacks } from "../../../../Types/ReactCallbacks";
import { SdDetectionConfig } from "../../../../Types/SdDetection";
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization";
import { D3SdDetectionConfigurationBuilder } from "./D3SdDetectionConfigurationBuilder";
import { D3SdDetectionRenderer } from "./D3SdDetectionRenderer";
import { Offset } from "../../../../Types/Offset";
import { EEGPage } from "../../../../Data/EEGPage";
import { TraceDataConfig } from "../../../../Types/Trace";
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants";
import { ModalityDataSource } from "../../../../Types/ModalityDataSource";
import { DataSource } from "../../../../Types/DataSource";
import { SDTimeSeriesPageManager } from "../../../../Data/SDTimeSeriesPageManager";

export class D3SdDetection extends D3TimeBasedVisualization<SdDetectionConfig, SdDetectionReactCallbacks, D3SdDetectionRenderer, SDTimeSeriesPageManager> {
    public graphBoundingBox: MarginedBoundingBox
    public margins: Offset = { top: 30, left: LEFT_MARGIN, bottom: 90, right: RIGHT_MARGIN }
    public mainEEGConfig: EEGDisplayConfig
    public overlayEEGConfig: EEGDisplayConfig
    public channelScale: ScaleBand<string> = scaleBand()
	public overlayBoundingBox: MarginedBoundingBox
	public overlayMargins = { top: 0, left: 0, bottom: 0, right: 0 }
	public mainEEGSensitivityScale: ScaleLinear<any, any, any> = scaleLinear()
    public overlayEEGSensitivityScale: ScaleLinear<any, any, any> = scaleLinear()

    constructor(root: HTMLDivElement, config: SdDetectionConfig, pageManager: SDTimeSeriesPageManager, reactCallbacks: SdDetectionReactCallbacks) {
        super(root, config, pageManager, reactCallbacks)
        this.graphBoundingBox = new MarginedBoundingBox(config.dimensions, this.margins)
        this.overlayBoundingBox = new MarginedBoundingBox(config.dimensions, this.overlayMargins)
        this.mainEEGConfig = new EEGDisplayConfig(config.montage, config.eegConfig.LFF, config.eegConfig.HFF, config.eegConfig.notch, config.eegConfig.sensitivityMicroVolts, config.montageMapping)
        this.overlayEEGConfig = new EEGDisplayConfig(config.montage, config.overlayEEGConfig.LFF, config.overlayEEGConfig.HFF, config.overlayEEGConfig.notch, config.overlayEEGConfig.sensitivityMicroVolts, config.montageMapping)

        this.mount(new D3SdDetectionRenderer(this, new D3SdDetectionConfigurationBuilder(this), "d3-sd-detection"))
    }

    getLastHoveredDate = () => this.renderer?.getLastHoveredDate()

	getChannels = () => this.mainEEGConfig.channels

	getOverlay = () => this.renderer?.overlay

    public getVisibleTraces(): TraceDataConfig[] {
        return this.getChannels()
            .filter(channel => channel !== "EEG_Ref")
            .map((channel, index) => ({ 
                id: channel,
                name: channel,
                dataKey: channel,
                rawDataKey: channel,
                dataSource: DataSource.CURRENT_PATIENT,
                color: "#000000", 
                units: "uV", 
                isCompositePart: true, 
                compositeIndex: index,
            })
        )
    }

	protected renderPage(page: EEGPage): void {
		this.renderer?.eegCanvases?.renderPage(page)
	}

	protected getModalityDataSources(): ModalityDataSource[] {
        const modalities = [this.mainEEGConfig?.eegModality, this.overlayEEGConfig?.eegModality]

        return modalities
            .filter((modality): modality is string => modality !== undefined)
            .map((modality: string) => ({
                modality,
                dataObjectId: this.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number,
            }) 
        )
	}
    
    protected updateDerivedState = () => {
        this.graphBoundingBox.setDimensions(this.config.dimensions)
		this.overlayBoundingBox.setDimensions({width: this.graphBoundingBox.width, height: this.graphBoundingBox.height})
        this.config.viewScale.range([0, this.graphBoundingBox.width])
        this.mainEEGConfig = new EEGDisplayConfig(this.config.montage, this.config.eegConfig.LFF, this.config.eegConfig.HFF, this.config.eegConfig.notch, this.config.eegConfig.sensitivityMicroVolts, this.config.montageMapping)
        this.overlayEEGConfig = new EEGDisplayConfig(this.config.montage, this.config.overlayEEGConfig.LFF, this.config.overlayEEGConfig.HFF, this.config.overlayEEGConfig.notch, this.config.overlayEEGConfig.sensitivityMicroVolts, this.config.montageMapping) 
        
        this.channelScale
            .domain(this.mainEEGConfig.channelDataKeys)
            .range([0, this.graphBoundingBox.height])

		this.mainEEGSensitivityScale
			.domain([-this.config.eegConfig.sensitivityMicroVolts * 1e6, this.config.eegConfig.sensitivityMicroVolts * 1e6])
			.range([-4054, 4054])

        this.overlayEEGSensitivityScale
            .domain([-this.config.overlayEEGConfig.sensitivityMicroVolts * 1e6, this.config.overlayEEGConfig.sensitivityMicroVolts * 1e6])
            .range([-4054, 4054])

		this.renderer?.updateChildren()
    }
}