import { Selection, EnterElement } from "d3"
import { D3VisualizationRenderer } from "../../D3VisualizationRenderer"
import { D3CPPOptPlot } from "./D3CPPOptPlot"
import { D3CPPOptPlotConfigurationBuilder } from "./D3CPPOptPlotConfigurationBuilder"
import { D3ClipPath } from "../../../D3/D3ClipPath"
import { CPPOptPlotConfig } from "../../../../Types/CPPOptPlot"
import { throttledRender } from "../../../../rendering"
import { D3Timeline } from "../../../D3/Timeline/D3Timeline"
import { D3HistogramXAxis } from "../../Histogram/D3/D3HistogramXAxis"
import { D3ValueAxis } from "../../../D3/D3ValueAxis"
import { D3HorizontalLines } from "../../../D3/D3HorizontalLines"
import { D3Parabola } from "../../../D3/D3Parabola"
import { D3ErrorBars } from "./D3CPPOptErrorBars"
import { D3Point } from "../../../D3/D3Point"
import { LEFT_MARGIN } from "../../Constants"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { D3CPPOptDebugInfo } from "./D3CPPOptDebugInfo"
import { MobergAnimationTimingMs } from "../../../../../../../../Moberg"
import { DataSource } from "../../../../Types/DataSource"
import { CompositeTimeSeriesData } from "../../../../Data/TimeSeriesData"

export class D3CPPOptPlotRenderer extends D3VisualizationRenderer<D3CPPOptPlot, D3CPPOptPlotConfigurationBuilder> {
	// CHILDREN
	private clipPath?: D3ClipPath
	private xAxis?: D3HistogramXAxis
	private yAxis?: D3ValueAxis
	private horizontalLines?: D3HorizontalLines
	private parabola?: D3Parabola
	private errorBars?: D3ErrorBars
	private minimumPoint?: D3Point
	private debugInfo?: D3CPPOptDebugInfo
	private llaPoint?: D3Point
	private ulaPoint?: D3Point

	public onYAxisDragStart(): void {
		this.configBuilder.transitionDuration = 0
		this.updateChildren()
	}

	public onYAxisDrag(): void {
		this.horizontalLines?.render()
		this.parabola?.render()
		this.errorBars?.render()
		this.minimumPoint?.render()
		this.llaPoint?.render()
		this.ulaPoint?.render()
	}

	public onYAxisDragEnd(): void {
		this.configBuilder.transitionDuration = MobergAnimationTimingMs.SLOW
		this.updateChildren()
	}

	public viewTimesChanged(): void {
		this.timeline?.viewTimesChanged()
		this.updateCPPOptData()
	}

	public onTimelineSliderDrag(): void {
		this.updateCPPOptData()
	}

	public renderPage(page: ModalityPage) {
		this.updateCPPOptData()
	}

	protected canRender(): boolean {
		return true // this.visualization.boundingBox.height > 0 && this.visualization.boundingBox.width > 0
	}

	protected enter(enterElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> {
		this.updateCPPOptData()
		
		const flexContainer = enterElements
			.append("div")
			.attr("class", this.className)
			.style("display", "flex")
			.style("flex-direction", "column")

			// SVGs are absolutely positioned. To mimic this behavior and let CSS grid handle the layout,
			// we have to absolutely position this div to only let it take up only the room we give it.
			.style("z-index", 1)
			.style("position", "absolute")
			.style("top", 0)
			.style("left", 0)
			.style("width", "100%")
			.style("height", "100%")

		// Create the plot container
		const plotContainer = flexContainer
			.append("div")
			.style("flex", 1)
			.style("min-height", 0)

		const upperSvg = plotContainer
			.append("svg")
			.style("display", "block")
			.attr("width", "100%")
			.attr("height", "100%")

		const upperBoundingBox = upperSvg
			.append("g")
			.attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y})`)

		// Create the table container
		const tableContainer = flexContainer
			.append("div")
			.style("display", "flex")
			.style("flex", 0)
			// .style("min-height", "160px")
			.style("overflow", "auto")
			.style("align-items", "center")
			.style("justify-content", "center")

		// Create the timeline container
		const timelineFlexContainer = flexContainer
			.append("div")
			.style("flex", 0)
			.style("min-height", "90px")

		const timelineSvg = timelineFlexContainer
			.append("svg")
			.attr("class", "lower-svg")
			.attr("width", "100%")
			.attr("height", "100%")

		const timelineContainer = timelineSvg
			.append("g")
			.attr("transform", `translate(${LEFT_MARGIN}, 32)`)

		upperBoundingBox.each((config, index, nodes) => this.createUpperBoundingBoxChildren(config, index, nodes))
		tableContainer.each((config, index, nodes) => this.createTable(config, index, nodes))
		timelineContainer.each((config, index, nodes) => this.createTimeline(config, index, nodes))
		upperSvg.each((config, index, nodes) => this.createClipPath(config, index, nodes))

		this.renderChildren()

		return flexContainer
	}

	private createUpperBoundingBoxChildren(config: CPPOptPlotConfig, index: number, nodes: ArrayLike<SVGGElement>) {
		const root = nodes[index]

		this.yAxis = new D3ValueAxis(root, this.configBuilder.getYAxisConfig(), this.visualization.reactCallbacks)
		this.xAxis = new D3HistogramXAxis(root, this.configBuilder.getXAxisConfig(), this.visualization.reactCallbacks)
		this.horizontalLines = new D3HorizontalLines(root, this.configBuilder.getHorizontalLinesConfig())
		this.parabola = new D3Parabola(root, this.configBuilder.getParabolaConfig(), this.visualization.reactCallbacks)
		this.errorBars = new D3ErrorBars(root, this.configBuilder.getErrorBarsConfig(), this.visualization.reactCallbacks)

		// Uncomment this line to render the Debug info
		// this.debugInfo = new D3CPPOptDebugInfo(root, this.configBuilder.getDebugInfoConfig(), this.visualization.reactCallbacks)

		const cppOpt = this.configBuilder.cppOptData.CPPOpt ?? 0
		this.minimumPoint = new D3Point(root, this.configBuilder.getMinimumPointConfig(cppOpt, this.parabola.evaluate(cppOpt)), "d3-minimum-point", this.visualization.reactCallbacks)

		const LLA = this.configBuilder.cppOptData.LLA ?? 0
		this.llaPoint = new D3Point(root, this.configBuilder.getLLAPointConfig(LLA, this.parabola.evaluate(LLA)), "d3-lla-point", this.visualization.reactCallbacks)

		const ULA = this.configBuilder.cppOptData.ULA ?? 0
		this.ulaPoint = new D3Point(root, this.configBuilder.getULAPointConfig(ULA, this.parabola.evaluate(ULA)), "d3-ula-point", this.visualization.reactCallbacks)
	}

	private createTable(config: CPPOptPlotConfig, index: number, nodes: ArrayLike<HTMLDivElement>) {
		// TODO: maybe replace the table with something more useful
		// const root = nodes[index]
		// ReactDOM.render(<CPPOptTable />, root)
	}

	private createClipPath(config: CPPOptPlotConfig, index: number, nodes: ArrayLike<SVGSVGElement>) {
		const root = nodes[index]
		this.clipPath = new D3ClipPath(root, this.configBuilder.getClipPathConfig(), this.visualization.reactCallbacks)
	}

	protected update = (updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const flexContainer = updatedElements

		this.renderChildren()

		return flexContainer
	}

	private createTimeline = (config: CPPOptPlotConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.timeline = new D3Timeline(root, this.configBuilder.getTimelineConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

	private renderChildren = () => {
		this.yAxis?.render()
		this.xAxis?.render()
		this.timeline?.render()
		this.horizontalLines?.render()
		this.parabola?.render()
		this.errorBars?.render()
		this.minimumPoint?.render()
		this.debugInfo?.render()
		this.llaPoint?.render()
		this.ulaPoint?.render()
	}

	public updateChildren = () => {
		this.yAxis?.updateConfig(this.configBuilder.getYAxisConfig())
		this.xAxis?.updateConfig(this.configBuilder.getXAxisConfig())
		this.timeline?.updateConfig(this.configBuilder.getTimelineConfig())
		this.clipPath?.updateConfig(this.configBuilder.getClipPathConfig())
		this.parabola?.updateConfig(this.configBuilder.getParabolaConfig())
		this.horizontalLines?.updateConfig(this.configBuilder.getHorizontalLinesConfig())
		this.errorBars?.updateConfig(this.configBuilder.getErrorBarsConfig())
		this.debugInfo?.updateConfig(this.configBuilder.getDebugInfoConfig())

		const cppOpt = this.configBuilder.cppOptData.CPPOpt ?? 0
		this.minimumPoint?.updateConfig(this.configBuilder.getMinimumPointConfig(cppOpt, this.parabola?.evaluate(cppOpt) ?? 0))

		const LLA = this.configBuilder.cppOptData.LLA ?? 0
		this.llaPoint?.updateConfig(this.configBuilder.getLLAPointConfig(LLA, this.parabola?.evaluate(LLA) ?? 0))

		const ULA = this.configBuilder.cppOptData.ULA ?? 0
		this.ulaPoint?.updateConfig(this.configBuilder.getLLAPointConfig(ULA, this.parabola?.evaluate(ULA) ?? 0))
	}

	private updateCPPOptData = throttledRender(() => {
		// TODO: This isn't the best algorithm, but it at least it shows us some data.
		// Address this in https://moberganalytics.atlassian.net/browse/CONN-5052
		const [, latestPage] = this.visualization.timeSeriesPageManager.getPagesInView()
		const dataObjectId = this.visualization.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number
		const compositeData = latestPage?.data.get(dataObjectId)?.get("CPPOpt,Composite,SampleSeries") as CompositeTimeSeriesData

		if (!compositeData) {
			return
		}

		const times = compositeData.times

		let mostRelevantIndex = 0
		const endTime = this.visualization.config.viewScale.domain()[1].getTime()

		for (let i = 0; i < times.length; i++) {
			const time = times[i]

			if (time === undefined) {
				continue
			}

			if (time > endTime) {
				mostRelevantIndex = i
				break
			}
		}

		const latestCompositeData = new Array(compositeData.data.length).fill(null)
		
		for (let i = 0; i < compositeData.data.length; i++) {
			latestCompositeData[i] = compositeData.data[i][mostRelevantIndex]
		}

		const numBins = this.visualization.bins.length

		this.configBuilder.updateCPPOptData({
			CPPOpt: latestCompositeData[0],
			LLA: latestCompositeData[1],
			ULA: latestCompositeData[2],
			fitType: latestCompositeData[3],
			fitSpan: latestCompositeData[4],
			includedDataPercentage: latestCompositeData[5],
			coefficients: latestCompositeData.slice(6, 9),
			yValues: latestCompositeData.slice(9, 8 + numBins),
			errors: latestCompositeData.slice(8 + numBins, 7 + 2*numBins),
			dataPercentages: latestCompositeData.slice(7 + 2*numBins, 6 + 3*numBins),
			includedInFit: latestCompositeData.slice(6 + 3*numBins, 5 + 4*numBins),
			weight: null
		})

		this.updateChildren()
	}, this.configBuilder.transitionDuration)
}
