import { Selection } from "d3"

const ACCENT = "#207DEA"

export function createLeftCaratButton(selection: Selection<any, any, any, any>, onClick: () => void) {
    const group = selection.append("g")

    createButtonOutlineRectangle(group, onClick)
    createIconButtonPath(group, "M17,20 12,15 17,10", ACCENT, 1.5) // these are just xy coordinates based on the width
}

export function createLeftLinedCaratButton(selection: Selection<any, any, any, any>, onClick: () => void, onHover: () => void, onLeave: () => void) {
    const group = selection.append("g")

    createButtonOutlineRectangle(group, onClick, onHover, onLeave)
    createIconButtonPath(group, "M11,20.5 11,9.5", ACCENT, 1.5)
    createIconButtonPath(group, "M19,20 14,15 19,10", ACCENT, 1.5)

    return group
}

export function createRightCaratButton(selection: Selection<any, any, any, any>, onClick: () => void) {
    const group = selection.append("g")

    createButtonOutlineRectangle(group, onClick)
    createIconButtonPath(group, "M13,20 18,15 13,10", ACCENT, 1.5)

    return group
}


export function createRightLinedCaratButton(selection: Selection<any, any, any, any>, onClick: () => void, onHover: () => void, onLeave: () => void) {
    const group = selection.append("g")

    createButtonOutlineRectangle(group, onClick, onHover, onLeave)
    createIconButtonPath(group, "M19,20.5 19,9.5", ACCENT, 1.5)
    createIconButtonPath(group, "M11,20 16,15 11,10", ACCENT, 1.5)

    return group
}

export function createButtonOutlineRectangle(selection: Selection<any, any, any, any>, onClick: () => void, onHover?: () => void, onLeave?: () => void) {
    const background = selection.append("rect")
        .attr("cursor", "pointer")
        .attr("stroke-width", 0.5)
        .attr("width", 30)
        .attr("height", 30)
        .attr("fill-opacity", 0.2)
        .attr("fill", "transparent")
        .attr("stroke", "#B6B6B6")
        .attr("rx", 5)

    background
        .on("mousedown", () => {
            background.attr("fill", "#CCC");
            onClick()
        })
        .on("mouseleave", () => background.attr("fill", "transparent"))
        .on("mouseup", () => background.attr("fill", "transparent"))

    if (onHover) {
        background.on("mousemove", () => onHover())
        background.on("mouseenter", () => onHover())
    }

    if (onLeave) {
        background.on("mouseleave", () => onLeave())
    }

    return selection
}

export function createIconButtonPath(selection: Selection<any, any, any, any>, path: string, color: string, strokeWidth: number) {
    selection
        .append("path")
        .attr("shape-rendering", "optimizeQuality")
        .attr("pointer-events", "none")
        .attr("stroke", color)
        .attr("stroke-width", strokeWidth)
        .attr("fill", "none")
        .attr("d", path)

    return selection
}
