import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { currentPatientFileInfoAtom } from "../../../Atoms/PatientFile"
import { MobergColumn, MobergDropdown, MobergInputLabel } from "../../../../../../../Moberg"
import { Analytic, CPPOptAnalysis } from "../../../Types/AnalysisDetails"

type EditCPPOptProps = {
	initialState: CPPOptAnalysis
	onChange: (analysis: CPPOptAnalysis) => void
}

export const EditCPPOpt: React.FC<EditCPPOptProps> = ({ initialState, onChange }) => {
	const { patientModalities } = useRecoilValue(currentPatientFileInfoAtom)
	const sortedPatientModalities = [...patientModalities].sort()

	const [ABP, setABPModality] = useState<string>(initialState.ABP)
	const [ICP, setICPModality] = useState<string>(initialState.ICP)

	useEffect(() => {
		onChange({
			analytic: Analytic.CPPOpt,
			ABP,
			ICP,
			calculationPeriodMs: 1000, // TODO: support this later.
			calculationWindowMs: 1000 // TODO: support this later.
		})
	})

	return (
		<MobergColumn gap="16px">
			<MobergColumn gap="8px">
				<MobergInputLabel text={"ABP"} />

				<MobergDropdown
					options={sortedPatientModalities.map(modality => ({ label: modality, value: modality }))}
					onChange={newValue => setABPModality(newValue)}
					selectedValue={ABP}
				/>
			</MobergColumn>

			<MobergColumn gap="8px">
				<MobergInputLabel text={"ICP"} />

				<MobergDropdown
					options={sortedPatientModalities.map(modality => ({ label: modality, value: modality }))}
					onChange={newValue => setICPModality(newValue)}
					selectedValue={ICP}
				/>
			</MobergColumn>
		</MobergColumn>
	)
}
