import React from "react"
import { Analytic, CPPOptAnalysis, MAPOptAnalysis, OnDemandAnalysis, PRxAnalysis } from "../../../Types/AnalysisDetails"
import { EditPRx } from "./EditPRx"
import { EditCPPOpt } from "./EditCPPOpt"
import { AnalysisBuilder } from "./AnalysisBuilder"
import { EditMAPOpt } from "./EditMAPOpt"

export const getAnalysisEditor = (analysis: OnDemandAnalysis | undefined, rawDataKey: string | undefined, onChange: (analysis: OnDemandAnalysis | undefined) => void) => {
    switch (analysis?.analytic) {
        case Analytic.PRx: return <EditPRx initialState={analysis as PRxAnalysis} onChange={onChange} />
        case Analytic.CPPOpt: return <EditCPPOpt initialState={analysis as CPPOptAnalysis} onChange={onChange} />
        case Analytic.MAPOpt: return <EditMAPOpt initialState={analysis as MAPOptAnalysis} onChange={onChange} />
        default:
            return <AnalysisBuilder 
                initialState={analysis ?? undefined}
                rawDataKey={rawDataKey as string} 
                onChange={onChange} />
    }
}
