import { ScaleTime } from "d3"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { ModalityGraph } from "../../../../Types/ModalityGraph"
import { AnnotationGeometry } from "./AnnotationGeometry"
import { GraphSpecificAnnotationGeometryGenerator } from "./GraphSpecificAnnotationGeometryGenerator"
import { getFullTraceDataConfigs } from "../../../../Types/Trace"

export class ModalityGraphAnnotationGeometryGenerator extends GraphSpecificAnnotationGeometryGenerator {
    modalitySpecificGeometry(annotation: Annotation, graph: ModalityGraph, xScale: ScaleTime<any, any, any>): AnnotationGeometry | null {
        let sharedModalities: string[]

        try {
            sharedModalities = graph.traces
                .flatMap(trace => getFullTraceDataConfigs(trace))
                .filter(trace => annotation.modalities.find(annotationModality => trace.rawDataKey?.startsWith(annotationModality)))
                .map(trace => trace.rawDataKey) as string[]

        } catch {
            return null
        }

        if (sharedModalities.length > 0) {
            return this.createGeometry(annotation, graph, xScale, { height: graph.height })
        }

        return null
    }

	generate(annotation: Annotation, graph: ModalityGraph, xScale: ScaleTime<any, any, any>): AnnotationGeometry | null {
		if (annotation.isModalitySpecific()) {
			return this.modalitySpecificGeometry(annotation, graph, xScale)
		}

        return this.createGeometry(annotation, graph, xScale, { y: graph.offset, height: graph.height })
	}
}
