import styled from "styled-components"
import { MobergAnimationCurve, MobergAnimationTiming, MobergFontFamily, MobergTheme, MobergThemeColors, getColors } from "../../Moberg"
import React, { CSSProperties, MouseEvent } from "react"

type MobergHoverRowProps = {
    theme: MobergTheme
    active: boolean
	onClick?: (event: MouseEvent<any>) => void
	style?: CSSProperties
}

type StyledRowProps = {
	colors: MobergThemeColors
	active: boolean
}

export const MobergHoverRow: React.FC<MobergHoverRowProps> = ({ theme, active, children, onClick, style }) => {
    return (
        <StyledRow colors={getColors(theme)} active={active} style={style} onMouseDown={onClick}>
            {children}
        </StyledRow>
    )
}

const StyledRow = styled.div<StyledRowProps>`
	display: flex;
	align-items: center;
	font-family: ${MobergFontFamily.REGULAR};
	font-weight: bold;
	border-radius: 6px;
	cursor: pointer;
	justify-content: space-between;
    padding: 0 16px;
	padding-right: 8px;
	transition: background-color ${MobergAnimationTiming.VERY_FAST} ${MobergAnimationCurve.EASE_IN};

	background-color: ${({ colors, active }) => (active ? colors.noFill.active : "unset")};

	&:hover {
		background-color: ${({ colors }) => colors.noFill.active};
	}
`