import styled from "styled-components"
import {
	MobergAnimationCurve,
	MobergAnimationTiming,
	MobergButton,
	MobergButtonShape,
	MobergFontFamily,
	MobergFontSize,
	MobergIconSize,
	MobergMenuItem,
	MobergTheme,
	MobergThemeColors,
	getColors,
} from "../../../Moberg"
import React from "react"
import { MdDelete, MdMoreVert, MdSettings } from "react-icons/md"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { selectedLayoutAtom, selectedLayoutGroupAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { Layout } from "../../../Pages/Data/Visualize/DataReview/Types/Layout"
import { useMobergMenu } from "../../../Hooks/useMobergMenu"
import { useLayoutService } from "../../../Hooks/useLayoutService"
import { useModalProvider } from "../../../Providers/ModalProvider"
import ConfirmationModal from "../../../Components/ConfirmationModal/ConfirmationModal"
import { RenameLayoutMenuItem } from "./RenameLayoutMenuItem"
import { CopyLayoutMenuItem } from "./CopyLayoutMenuItem"

type TabButtonProps = {
	layout: Layout
	isActive: boolean
	isDragging?: boolean
}

type DisplayTabButtonProps = {
	isActive: boolean
	isDragging?: boolean
	colors: MobergThemeColors
}

export const TabButton = ({ layout, isActive, isDragging }: TabButtonProps) => {
	const setSelectedLayout = useSetRecoilState(selectedLayoutAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const { removeLayoutFromLayoutGroup, openConfigureLayoutModal } = useLayoutService()
	const actionsMenu = useMobergMenu({ placement: "top-start" })
	const { createModal } = useModalProvider()

	const confirmDeleteLayout = () => {
		if (!selectedLayoutGroup) {
			return
		}

		actionsMenu.setClickOutsideClose(false)

		createModal(
			<ConfirmationModal
				title={"Delete display?"}
				description={`This will delete ${layout.name} from ${selectedLayoutGroup.name}. This is a permanent action and cannot be undone.`}
				confirmButton={{
					text: "Yes, delete",
					theme: MobergTheme.RED,
					onClick: () => removeLayoutFromLayoutGroup(selectedLayoutGroup, layout.id),
				}}
				afterClose={() => actionsMenu.setClickOutsideClose(true)}
			/>,
			{zIndex: 10001}
		)
	}

	const hasMaxDisplays = (selectedLayoutGroup?.layouts.length ?? 0) > 9

	return (
		<DisplayTabButton 
			isActive={isActive} 
			isDragging={isDragging}
			colors={isActive ? getColors(MobergTheme.BLUE) : getColors(MobergTheme.BLACK)}
			onMouseDown={() => setSelectedLayout(layout)}
		>
			<div style={{ display: "flex", gap: "4px", alignItems: "center", height: "100%" }}>
				<h4
					style={{
						fontFamily: MobergFontFamily.REGULAR,
						fontSize: MobergFontSize.REGULAR,
						fontWeight: "bold",
						textAlign: "center",
						margin: 0,
					}}
				>
					{layout.name}
				</h4>

				{/* Because the Moberg button is used as an anchor point for animation, we can't just pop in and out of the screen. 
					This container makes it invisible without conditionally rendering it. */}
				<div style={{ 
					overflow: "hidden", 
					width: isActive ? "24px" : "0px", 
					opacity: isActive ? 1 : 0,
					transition: `all ${MobergAnimationTiming.REGULAR} ${MobergAnimationCurve.EASE_IN}`,
					}}>
					<MobergButton
						shape={MobergButtonShape.SQUARE}
						theme={MobergTheme.ON_BLUE}
						onClick={event => actionsMenu.open(event)}
						style={{ padding: "2px" }}
					>
						<MdMoreVert size={MobergIconSize.SMALL} />
					</MobergButton>
				</div>
				
			</div>

			<actionsMenu.MobergMenu style={{ padding: "8px 0", marginBottom: "6px" }}>
				<MobergMenuItem onClick={() => { actionsMenu.close(); openConfigureLayoutModal(layout.id) }}>
					<MdSettings size={MobergIconSize.REGULAR} />
					Edit display
				</MobergMenuItem>
				
				<RenameLayoutMenuItem
					parentMenu={actionsMenu} 
					layout={layout}
				/>
				
				<CopyLayoutMenuItem 
					parentMenu={actionsMenu} 
					layout={layout} 
					disabled={hasMaxDisplays}
					tooltip={hasMaxDisplays ? "There cannot be more than 10 different displays." : ""}
				/>

				<MobergMenuItem onClick={confirmDeleteLayout}>
					<MdDelete size={MobergIconSize.REGULAR} />
					Delete
				</MobergMenuItem>
			</actionsMenu.MobergMenu>
		</DisplayTabButton>
	)
}

const DisplayTabButton = styled.div<DisplayTabButtonProps>`
	border-radius: 6px;
	cursor: pointer;
	padding-left: ${({ isActive }) => (isActive ? "16px" : "24px")};
	padding-right: ${({ isActive }) => (isActive ? "8px" : "24px")};
	height: 36px;
	transition: background-color ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN}, padding ${MobergAnimationTiming.REGULAR} ${MobergAnimationCurve.EASE_IN};

	background-color: ${({ isActive, isDragging, colors }) => (isActive || isDragging ? colors.noFill.active : "#f8f8f8")};

	&:hover {
		background-color: ${({ colors }) => colors.noFill.active};
	}
`
