import styled from "styled-components";
import { Searchbar } from "../../../../Constants/StyledComponents";
import * as MdIcons from 'react-icons/md';
import { IntrusiveCheckboxButton, CheckboxButton } from "../../../../Components/CheckboxButton/CheckboxButton";
import { useState, useEffect } from "react";
import { useAnnotationsProvider, annotationModalityUpdates } from "../../../../Providers/AnnotationsProvider";
import { selectedAnnotationAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { useRecoilValue } from "recoil";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { visibleTracesAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Traces"
import { useOnMount } from "../../../../Hooks/useOnMount";
import { analyticTracesAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Analytics";

const EditAnnotationModalities = (props) => {
    const annotationsProvider = useAnnotationsProvider()
    const analyticTraces = useRecoilValue(analyticTracesAtom)

    useOnMount(() => {
        annotationsProvider.update(annotationModalityUpdates.MODALITIES_UNION)
    })

    const selectedAnnotationAtomAnnotation = useRecoilValue(selectedAnnotationAtom)

    // if the EditAnnotation modal is opened up from HotkeysModal.js, then the configs should come from props rather than the selectedAnnotationAtom
    const selectedAnnotation = props.mode === "CREATE" ? props.config : props.mode === "EDIT" ? { ...props.config.data, id: props.config.id } : selectedAnnotationAtomAnnotation

    // this useState is responsible for holding all modalities (from the get_modalities_union_v2 endpoint called in AnnotationsProvider) and will be in the form: [{label: 'ABP_Mean', name: 'ABP,Dias,Numeric,Float,IntelliVue,data', type: 'Trends', checked: false}]
    const [modalities, setModalities] = useState([])

    // this useState holds the 3 modality types/sub-sections in the Modalities tab. when expanded is false, only the sub-section name is shown and the list of modalities is minimized for that sub-section
    const [modalitiesTypes, setModalitiesTypes] = useState([
        { type: 'EEG', expanded: true },
        { type: 'Trends', expanded: true },
        { type: 'Waveforms', expanded: true },
        { type: 'Analytics', expanded: true }
    ])

    // this useState holds the search value for the search bar inside the Modalities tab. this searches for a modality's label and name
    const [modalitiesSearchQuery, setModalitiesSearchQuery] = useState('')

    // useState contains all selected/checked modality labels in an array of strings in the form: ['EEG_C3', 'EEG_C4']
    const [selectedModalities, setSelectedModalities] = useState([])

    /*
     * A React useEffect hook that gets modalities from get_modalities_union_v2 endpoint and formats them accordingly.
     * It also handles "pre-selecting" modalities when opening edit annotation modal for the selectedAnnotation.
    */
    useEffect(() => {
        let data = annotationsProvider.modalitiesUnion
        if (Object.keys(data).length === 0) {
            return
        }

        // formats modalities returned from the endpoint into the form: [{label: 'ABP_Mean', name: 'ABP,Dias,Numeric,Float,IntelliVue,data', type: 'Trends', checked: false}]
        let formattedModalities = Object.keys(data).map((name) => {
            if ((data[name]['type'] === 'SampleSeries') && (name.includes('EEG'))) {
                return {
                    label: name,
                    name: data[name]['file_name'],
                    type: 'EEG',
                    checked: false
                }
            } else if (data[name]['type'] === 'Numeric') {
                return {
                    label: name,
                    name: data[name]['file_name'],
                    type: 'Trends',
                    checked: false
                }
            } else {
                return {
                    label: name,
                    name: data[name]['file_name'],
                    type: 'Waveforms',
                    checked: false
                }
            }
        })

        formattedModalities.push(...analyticTraces.map(trace => ({
            label: trace.name,
            name: trace.name,
            type: "Analytics",
            checked: false
        })))

        // handles pre-selection if there are any modalities selected when initially rendering the Edit Annotation modal
        let updatedModalities = formattedModalities.map((modality) => {
            if (selectedAnnotation.modalities.includes(modality.label)) {
                setSelectedModalities(prevState => [...prevState, modality.label])
                return { ...modality, checked: true }
            } else {
                setSelectedModalities(prevState => prevState.filter(mod => mod !== modality.label))
                return { ...modality, checked: false }
            }
        })

        setModalities(updatedModalities)

        //annotationsProvider.setUneditedModalitiesProvider stores the original modalities configs on first render. This is used for the 'Reset changes' functionality
        annotationsProvider.setUneditedModalitiesProvider(updatedModalities)

        //annotationsProvider.annotationModalities stores selected modalities to handle saving changes in EditAnnotation.js
        annotationsProvider.setAnnotationModalities(updatedModalities)
    }, [annotationsProvider.modalitiesUnion])


    // useState stores a Boolean value to handle the 'Select visible traces only' checkbox
    const [visibleTracesOnly, setVisibleTracesOnly] = useState(false)

    // rawVisibleTraces stores a dictionary in the form: [{name: 'EEG_Fp1', dataKey: 'EEG_F7', color: '#000000', units: 'uV'}]
    const rawVisibleTraces = useRecoilValue(visibleTracesAtom)

    // visibleTraces stores an array of strings in the form: ['EEG_Fp1', 'EEG_F7', 'EEG_T7']
    const visibleTraces = rawVisibleTraces.map((trace) => trace.rawDataKey)

    /**
    * Handles returning the color of the modality in the layout
    *
    * @param {String} label - The label of the modality
    */
    function getVisibleTraceColor(label) {
        let color = rawVisibleTraces.filter(trace => trace.rawDataKey === label).map(modality => modality.color)[0]
        return color ?? "black" // If the trace does not have a color, default to black
    }

    /**
    * Handles returning the text color of the modality checkbox as either white or black depending on the background color of the modality checkbox.
    *
    * @param {String} backgroundColor - The background color of the modality checkbox
    */
    function getContrastColor(backgroundColor) {
        const r = parseInt(backgroundColor.slice(1, 3), 16);
        const g = parseInt(backgroundColor.slice(3, 5), 16);
        const b = parseInt(backgroundColor.slice(5, 7), 16);

        // Use the relative luminance formula to calculate the contrast
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Choose white or black text based on the contrast
        return luminance > 0.5 ? '#000000' : '#ffffff';
    }

    /*
     * A React useEffect hook that automatically updates the value of visibleTracesOnly so that the 'Select visible traces only' checkbox will check/uncheck dynamically based on the selected modalities
    */
    useEffect(() => {
        let visible = [...new Set(visibleTraces)].slice().sort().join(' ')

        let selected = (Object.keys(annotationsProvider.annotationModalities)
            .filter(index => annotationsProvider.annotationModalities[index]['checked'] === true)
            .map(modality => annotationsProvider.annotationModalities[modality]['label']))
            .slice().sort().join(' ')

        if (selected === visible) {
            setVisibleTracesOnly(true)
        } else {
            setVisibleTracesOnly(false)
        }
    }, [visibleTraces, annotationsProvider.annotationModalities])


    /**
    * Handles selecting/unselecting modalities when checking/unchecking the 'Select visible traces only' checkbox.
    *
    * @param {Boolean} nextState - The state of the checkbox. True if it is not checked and false if it is checked.
    */
    function onCheckVisibleTraces(nextState) {
        // handles checking/selecting visible traces
        if (nextState) {
            let updatedModalities = modalities.map((modality) => {
                if (visibleTraces.includes(modality.label)) {
                    setSelectedModalities(prevState => [...prevState, modality.label])
                    return { ...modality, checked: true }
                } else {
                    setSelectedModalities(prevState => prevState.filter(mod => mod !== modality.label))
                    return { ...modality, checked: false }
                }
            })

            setVisibleTracesOnly(true)
            setModalities(updatedModalities)
            annotationsProvider.setAnnotationModalities(updatedModalities)
            annotationsProvider.setUnsavedModalityEdits(true)
        } else {
            //handles unchecking/unselecting all modalities after unchecking the 'Select visible traces only' checkbox
            setSelectedModalities([])
            let updatedModalities = modalities.map((modality) => {
                return { ...modality, checked: false }
            })
            setModalities(updatedModalities)
            annotationsProvider.setAnnotationModalities(updatedModalities)
            setVisibleTracesOnly(false)
        }
    }

    /**
    * Handles selecting/unselecting all EEG modalities when checking/unchecking the 'EEG' checkbox.
    *
    * @param {Boolean} nextState - The state of the checkbox. True if it is not checked and false if it is checked.
    * @param {String} type - The type of modality (EEG, Trends, Waveforms)
    */
    function onCheckAllTypeModalities(nextState, type) {
        // check all modalities with the same type
        if (nextState) {
            let newSelectedModalities = modalities.filter(modality => modality.type === type).map(modality => modality.label)

            setSelectedModalities(prevState => [...prevState, ...newSelectedModalities])

            const updatedModalities = modalities.map((modality) => {
                if (modality.type === type) {
                    return { ...modality, checked: true }
                }
                return modality
            })
            setModalities(updatedModalities)
            annotationsProvider.setAnnotationModalities(updatedModalities)

        } else {
            // uncheck all modalities with the same type
            const updatedModalities = modalities.map((modality) => {
                if (modality.type === type) {
                    setSelectedModalities(prevState => prevState.filter(selectedModality => selectedModality !== modality.label))
                    return { ...modality, checked: false }
                }
                return modality
            })
            setModalities(updatedModalities)
            annotationsProvider.setAnnotationModalities(updatedModalities)
        }
    }

    /**
    * Handles returning all the selected modalities of a particular type (EEG, Waveforms, Trends)
    *
    * @param {String} type - The modalities type (EEG, Trends, Waveforms)
    */
    function getModalityTypeCheckboxState(type) {
        let selectedTypeModalities = modalities.filter(modality => (modality.type === type && selectedModalities.includes(modality.label)))
        let allTypeModalities = modalities.filter(modality => modality.type === type)

        return (selectedTypeModalities.length === allTypeModalities.length)
    }

    /**
    * Handles selecting/unselecting a single modality when checking/unchecking its respective checkbox.
    *
    * @param {String} modalityLabel - The modality label (the name displayed for each modality checkbox)
    * @param {Boolean} nextState - The state of the checkbox. True if it is not checked and false if it is checked.
    */
    function onCheckSingleModality(modalityLabel, nextState) {
        // selects a singular modality designated by modalityLabel
        if (nextState) {
            setSelectedModalities(prevState => [...prevState, modalityLabel.label])
            const updatedModalities = modalities.map((modality) => {
                if (modality.label === modalityLabel.label) {
                    return { ...modality, checked: true };
                }
                return modality;
            });
            setModalities(updatedModalities);
            annotationsProvider.setAnnotationModalities(updatedModalities)
        } else {
            // unselects a singular modality designated by modalityLabel
            setSelectedModalities(prevState => prevState.filter(modalities => modalities !== modalityLabel.label))
            const updatedModalities = modalities.map((modality) => {
                if (modality.label === modalityLabel.label) {
                    return { ...modality, checked: false };
                }
                return modality;
            });
            setModalities(updatedModalities);
            annotationsProvider.setAnnotationModalities(updatedModalities)
        }
    }

    /**
    * Handles expanding and minimizing modality sub-sections, and updating the expand/minimize arrow icons for each modality sub-section
    *
    * @param {String} type - The modality sub-section/type. This is expected to be 'EEG', 'Waveforms', or 'Trends'
    */
    function expandCondenseTypes(type) {
        const updatedModalitiesTypes = modalitiesTypes.map(modalityType => {
            if (modalityType.type === type) {
                return { ...modalityType, expanded: !modalityType.expanded };
            }
            return modalityType;
        })
        setModalitiesTypes(updatedModalitiesTypes);
    }

    /**
    * Handles hiding modality sub-sections if there are no matching modalities in a sub-section for modalitiesSearchQuery
    *
    * @param {String} type - The modality sub-section/type. This is expected to be 'EEG', 'Waveforms', or 'Trends'
    */
    function checkModalitiesSearchQuery(type) {
        // if there is no search query, display all modality sub-sections and their contents
        if (modalitiesSearchQuery === "") {
            return true
        }
        // if there is a search query, return true if there are modalities of that type that contain the search query
        let containsSearchQuery = modalities.filter((modality) => {
            return (modality.type === type) && (modality.label.toLowerCase().includes(modalitiesSearchQuery.toLowerCase()) || modality.name.toLowerCase().includes(modalitiesSearchQuery.toLowerCase()))
        }).length
        return (containsSearchQuery > 0)
    }

    /**
    * Handles resetting selected modalities to the last saved version when pressing the 'Reset changes' button. 
    * This is done by setting all selected modality configs to annotationsProvider.uneditedModalitiesProvider
    */
    function resetUnsavedModalityChanges() {
        modalities.map((modality) => {
            if (annotationsProvider.uneditedModalitiesProvider.filter(mod => mod.label === modality.label).map(modality => modality.checked)[0]) {
                if (!(selectedModalities.includes(modality.label))) {
                    setSelectedModalities(prevState => [...prevState, modality.label])
                }
                return { ...modality, checked: true }
            } else {
                setSelectedModalities(prevState => prevState.filter(mod => mod !== modality.label))
                return { ...modality, checked: false }
            }
        })
        annotationsProvider.setAnnotationModalities(annotationsProvider.uneditedModalitiesProvider)
        setModalities(annotationsProvider.uneditedModalitiesProvider)
        annotationsProvider.setUnsavedModalityEdits(false)
    }

    /**
    * Handles returning modalities' full names when hovering over a modality as a popover.
    *
    * @param {String} label - The modality label displayed on each modality checkbox.
    */
    const getModalityName = (label) => {
        for (const key in modalities) {
            if (modalities[key].label === label) {
                return modalities[key]['name'];
            }
        }
        return null; // Return null if label not found
    }

    /**
    * Handles displaying a modality's full names when hovering over a modality as a popover.
    *
    * @param {String} label - The modality label displayed on each modality checkbox.
    */
    function getPopoverTop(label) {
        return (
            <Popover id="Popover-trigger-hover-focus" style={{ width: "228px" }} positionleft={75}>
                <p style={{ fontFamily: "Source Sans Pro", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#5F6775", paddingLeft: "3px" }}>
                    {`${getModalityName(label)}`}
                </p>
            </Popover>
        )
    }

    /**
    * Handles search bar functionality in the Modalities tab.
    *
    * @param {String} modality - The modality label displayed on each modality checkbox.
    */
    function getSearchQueryResults(modality) {
        if (modality.label.toLowerCase().includes(modalitiesSearchQuery.toLowerCase()) || modality.name.toLowerCase().includes(modalitiesSearchQuery.toLowerCase())) {
            return true
        } else {
            return false
        }
    }

    return (
        <div>
            <div style={{ display: "flex", height: "50px", alignItems: "center", position: 'relative', gap: '10px' }}>
                <Searchbar className="ui left icon input" style={{ background: (props.isHotkeysModal && JSON.stringify(props?.config?.triggers) === '[null,null]') || props?.config?.editModeWarning || props?.config?.isInEditMode ? '#FAFAFA' : '#FFFFFF' }}>
                    <input
                        type="text"
                        onChange={(e) => { setModalitiesSearchQuery(e.target.value) }}
                        disabled={(props.isHotkeysModal && JSON.stringify(props?.config?.triggers) === '[null,null]') || props?.config?.editModeWarning || props?.config?.isInEditMode}
                    />
                    <i aria-hidden="true" class="search icon" />
                </Searchbar>

                <div style={{ display: "flex", color: "#207DEA", fontFamily: "Source Sans Pro", fontWeight: "600", fontSize: "14px", lineHeight: "150%", cursor: "pointer" }} onClick={() => { onCheckVisibleTraces(!visibleTracesOnly) }}>
                    <CheckboxButton
                        style={{ color: "#207DEA", width: "15px", marginRight: "2px", cursor: "pointer" }}
                        state={visibleTracesOnly}
                        action={(nextState) => {
                            onCheckVisibleTraces(nextState)
                        }}
                    />
                    Select visible traces only
                </div>

                <ResetButton style={{ opacity: annotationsProvider.unsavedModalityEdits ? "1" : "0.3", cursor: annotationsProvider.unsavedModalityEdits ? "pointer" : "none", pointerEvents: annotationsProvider.unsavedModalityEdits ? "all" : "none" }} onClick={() => { resetUnsavedModalityChanges() }}>
                    <MdIcons.MdRefresh size={20} style={{ color: "#207DEA", transform: "scaleX(-1)" }} />
                    <ResetChangesText>Reset changes</ResetChangesText>
                </ResetButton>
            </div>
            <hr style={{ border: "1px solid #EFF1F4", marginTop: "-2px", marginBottom: "0px" }} />

            <ModalitiesContainer>
                {modalitiesTypes.map((modalityType) => {
                    return (<>
                        <div style={{ display: checkModalitiesSearchQuery(modalityType.type) ? "inline-flex" : "none", width: "688px", position: "relative", alignItems: "center" }} >
                            <IntrusiveCheckboxButton
                                checkboxStyle={{ marginTop: "1px", marginRight: "4px", color: (getModalityTypeCheckboxState(modalityType.type)) ? "#637DA8" : "#AEB7C6" }}
                                checkboxSize={20}
                                state={getModalityTypeCheckboxState(modalityType.type)}
                                action={(nextState) => { onCheckAllTypeModalities(nextState, modalityType.type) }}
                            />
                            <h2 onClick={() => { onCheckAllTypeModalities(!(getModalityTypeCheckboxState(modalityType.type)), modalityType.type) }} style={{ margin: "0", cursor: "pointer" }}>{modalityType.type}</h2>

                            <MdIcons.MdPlayArrow size={22} style={{ color: "#AEB7C6", transform: modalityType.expanded ? "rotate(90deg)" : "rotate(0deg)", marginTop: "6px", position: "absolute", right: "16px", cursor: "pointer" }} onClick={() => { expandCondenseTypes(modalityType.type) }} />
                        </div>
                        <div style={{ display: modalityType.expanded ? "flex" : "none", flexWrap: "wrap" }}>
                            {Object.values(modalities).map(val => {
                                if (val.type === modalityType.type) {
                                    if (modalitiesSearchQuery) {
                                        if (getSearchQueryResults(val)) {
                                            return (
                                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop(`${val.label}`)}>
                                                    <ModalityCheckbox style={{
                                                        color: visibleTraces.includes(val.label) ? getContrastColor(getVisibleTraceColor(val.label)) : val.checked ? "#637DA8" : "#AEB7C6", background: visibleTraces.includes(val.label) ? getVisibleTraceColor(val.label) : val.checked ? "#EFF1F4" : "#FFFFFF", opacity: (visibleTraces.includes(val.label) && !val.checked) ? "0.3" : (visibleTraces.includes(val.label) && val.checked) ? "0.7" : "1", display: "flex", alignItems: "center"
                                                    }} onClick={() => onCheckSingleModality(val, !val.checked)}>
                                                        <CheckboxButton
                                                            checkboxStyle={{
                                                                color: visibleTraces.includes(val.label) ? getContrastColor(getVisibleTraceColor(val.label)) : val.checked ? "#637DA8" : "#EFF1F4", cursor: "pointer"
                                                            }}
                                                            state={selectedModalities.includes(val.label)}
                                                            action={undefined}
                                                            checkboxSize={18}
                                                        />
                                                        {val.label}
                                                    </ModalityCheckbox>
                                                </OverlayTrigger>
                                            )
                                        }
                                    } else {
                                        return (
                                            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getPopoverTop(`${val.label}`)}>
                                                <ModalityCheckbox style={{
                                                    color: visibleTraces.includes(val.label) ? getContrastColor(getVisibleTraceColor(val.label)) : val.checked ? "#637DA8" : "#AEB7C6", background: visibleTraces.includes(val.label) ? getVisibleTraceColor(val.label) : val.checked ? "#EFF1F4" : "#FFFFFF", opacity: (visibleTraces.includes(val.label) && !val.checked) ? "0.3" : (visibleTraces.includes(val.label) && val.checked) ? "0.7" : "1", display: "flex", alignItems: "center"
                                                }} onClick={() => onCheckSingleModality(val, !val.checked)}>
                                                    <CheckboxButton
                                                        checkboxStyle={{
                                                            color: visibleTraces.includes(val.label) ? getContrastColor(getVisibleTraceColor(val.label)) : val.checked ? "#637DA8" : "#EFF1F4",
                                                            cursor: "pointer"
                                                        }}
                                                        state={selectedModalities.includes(val.label)}
                                                        action={undefined}
                                                        checkboxSize={18}

                                                    />
                                                    {val.label}
                                                </ModalityCheckbox>
                                            </OverlayTrigger>
                                        )
                                    }
                                }

                                return null // .map should always return something. If we need to filter, use .filter before .map.
                            })}
                        </div>
                        <hr style={{ border: "1px solid #EFF1F4", marginTop: "0px", marginBottom: "0px" }} />
                    </>)
                })}
            </ModalitiesContainer>
        </div>
    )
}

const ModalityCheckbox = styled.div`
    height: 24px;
    width: fit-content;
    display: inline-flex;
    margin-right: 6px;
    margin-bottom: 6px;
    border-radius: 4px;
    border: 1px solid #EFF1F4;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    gap: 4px;
`;

const ResetButton = styled.button`
    display: flex;
    justify-content: center;
    border: none;
    background: none;
    white-space: nowrap;
`;

const ResetChangesText = styled.p`
    color: #207DEA !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    text-decoration-line: underline !important;
`;

const ModalitiesContainer = styled.div`
    width: 688px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: clip;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ::-webkit-scrollbar {
        display: block;
        width: 5px;
        color: #313A4A;
    }
    ::-webkit-scrollbar-track {
        background: #BEC4CF;
        width: 5px;
        border-radius: 2px;
    }
`

export default EditAnnotationModalities;