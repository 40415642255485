import React, { useRef } from "react"
import { DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useRecoilValue } from "recoil"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { ModalityPageManager } from "../../../../Data/ModalityPageManager"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"
import { D3MAPOptPlot } from "../D3/D3MAPOptPlot"
import { MAPOptToolbar } from "./MAPOptToolbar"
import { MAPOptPlotConfig, MAPOptPlotJSON } from "../../../../Types/MAPOptPlot"
import { mapOptPlotConfigsAtom } from "../../../../Atoms/MAPOpt"

type MAPOptPlotProps = {
    windowId: string,
    json: MAPOptPlotJSON
}

export const MAPOptPlot = (props: MAPOptPlotProps) => {

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 0 }}>
                <MAPOptToolbar windowId={props.windowId} />
            </div>
            <div style={{ flex: 1 }}>
                <DimensionsProvider>
                    <MAPOptPlotVisualization {...props} />
                </DimensionsProvider>
            </div>
        </div>
    )
}

const MAPOptPlotVisualization = (props: MAPOptPlotProps) => {
    const container = useRef<HTMLDivElement>(null)
    const { getDataQuerySocket } = useSocketProvider()

    const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
    const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
    const atom = mapOptPlotConfigsAtom(visualizationId)
    const { fileStartDate, fileEndDate} = useRecoilValue(currentPatientFileInfoAtom)

    const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.MAPOPT_PLOT, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })
    const [viewStart, viewEnd] = viewScale.domain()
    const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const d3Controller = useD3Controller<D3MAPOptPlot, MAPOptPlotConfig, MAPOptPlotJSON, ModalityPageManager>({
		atom,
		nodeRef: container,
		initProps: props.json,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.MAPOPT_PLOT, new ModalityPageManager(), getDataQuerySocket),
		props: {
			id: props.windowId,
			viewScale,
			fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.MAPOPT_PLOT, fileStartDate, fileEndDate),
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3MAPOptPlot(node, config, pageManager, callbacks)
		},
        extractJSON(config) {
            return {
                viewDuration: viewDuration,
                playbackSpeed: config.playbackSpeed,
                abpModality: config.abpModality,
                stO2Modality: config.stO2Modality
            }
        },
	})

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [hotkeyActions.PREVIOUS_PAGE, hotkeyActions.NEXT_PAGE, hotkeyActions.PLAY_PAUSE]
    })

    useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
            viewDuration,
            abpModality: d3Controller?.config.abpModality,
            stO2Modality: d3Controller?.config.stO2Modality
        },
    })

    return (
        <div ref={container} style={{ height: "100%", position: "relative" }} />
    )
}