import React from "react"
import { OnDemandAnalysis } from "../../../Types/AnalysisDetails"
import { useModalProvider } from "../../../../../../../Providers/ModalProvider"
import { MobergButton, MobergButtonVariant, MobergColumn, MobergHeader, MobergRow, MobergTheme } from "../../../../../../../Moberg"
import { Scrollbar } from "../../../../../../../Constants/StyledComponents"
import { getAnalysisEditor } from "./AnalysisEditorFactory"

type EditAnalsysisModalProps = {
	analysis: OnDemandAnalysis | undefined
	rawDataKey: string | undefined
	onChange: (analysis: OnDemandAnalysis | undefined) => void
}

export const EditAnalysisModal: React.FC<EditAnalsysisModalProps> = ({ analysis, rawDataKey, onChange }) => {
	const { close } = useModalProvider()

	return (
		<MobergColumn gap="32px" style={{ width: "70vh", maxHeight: "95vh", minHeight: "500px", padding: "16px" }}>

			<MobergRow horizontalAlign="center">
				<MobergHeader>
					Edit Calculation
				</MobergHeader>

			</MobergRow>

			<Scrollbar style={{ flex: 1, padding: "0 16px" }}>
				{getAnalysisEditor(analysis, rawDataKey, onChange)}
			</Scrollbar>

			<MobergRow horizontalAlign="right">
				<MobergButton
					variant={MobergButtonVariant.FILLED}
					theme={MobergTheme.BLUE}
					onClick={close}
				>
					OK
				</MobergButton>
			</MobergRow>

		</MobergColumn>
	)
}
