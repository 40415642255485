import { Selection, EnterElement } from "d3"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { SolidColorTraceDataConfig } from "../../../../Types/Trace"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"

type D3LegendLabelsConfig = {
	traces: SolidColorTraceDataConfig[]
}

export class D3LegendLabels extends D3OneToManyRenderable<HTMLDivElement, D3LegendLabelsConfig, SolidColorTraceDataConfig> {

	constructor(root: HTMLDivElement, config: D3LegendLabelsConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-legend-labels", reactCallbacks)
		this.mount()
	}

	protected datumIdentifier(datum: SolidColorTraceDataConfig): string | number {
		return `${datum.name}-${datum.color}`
	}

	protected getConfigs(): SolidColorTraceDataConfig[] {
		return this.config.traces.filter(trace => trace.name !== undefined)
	}

	protected enter(newElements: Selection<EnterElement, SolidColorTraceDataConfig, Element, any>): Selection<HTMLDivElement, SolidColorTraceDataConfig, Element, any> {
		const elements = newElements
			.append("div")
            .attr("class", this.className)
			.style("color", this.getTraceColor)
			.text(trace => trace.name)

		return elements
	}

	protected update(updatedElements: Selection<HTMLDivElement, SolidColorTraceDataConfig, Element, any>): Selection<HTMLDivElement, SolidColorTraceDataConfig, Element, any> {
		return updatedElements
            .style("color", this.getTraceColor)
            .text(trace => trace.name)
	}

	private getTraceColor = (trace: SolidColorTraceDataConfig) => {
		return trace.color
	}
}
