import React, { useContext, useEffect, useRef, useState } from "react"
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergColumn, MobergDropdown, MobergIconSize, MobergInputLabel, MobergRow, MobergText, MobergTheme } from "../../../../../Moberg"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import { DeltaRegion, DeltaRegionJSON, HeatmapTraceConfig, LineTraceConfig, RenderStrategy, TraceConfig } from "../../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { MdClose } from "react-icons/md"
import { ChromePicker } from "react-color"
import { ColorRGB } from "../../../../../Pages/Data/Visualize/DataReview/Types/Color"

export const DeltaModalities = () => {
    const { currentGraph, activeDeltaRegions, updateGraphProperty, removeTrace } = useContext(ConfigureWindowModalContext)
    const [colorPickerDeltaRegionId, setColorPickerDeltaRegionId] = useState<string>()
	const colorPickerBlockRefs = useRef<Map<string, HTMLDivElement | null>>(new Map())
	const colorPickerRef = useRef<HTMLDivElement | null>(null)

    function addColorPickerBlockRef(node: HTMLDivElement | null, traceId: string) {
		colorPickerBlockRefs.current.set(traceId, node)
	}

    const updateDeltaRegion = (id: string, updatedDeltaRegion: DeltaRegion) => {
		const newTraces = [...(currentGraph?.traces ?? [])].map(trace => {
			if (trace.id === id) {
				return updatedDeltaRegion
			}

			return trace
		})

		updateGraphProperty("traces", newTraces)
	}

    const addDelta = () => {
		updateGraphProperty("traces", (previous: TraceConfig[]) => {
			const firstTrace = currentGraph?.traces.find(trace => trace.renderStrategy !== RenderStrategy.DELTA)

			if (!firstTrace) {
				return previous
			}

			const newTrace: DeltaRegionJSON = {
				id: `trace-${new Date(Date.now()).toISOString()}`,
				renderStrategy: RenderStrategy.DELTA,
				first: firstTrace,
				second: firstTrace,
				color: { r: 0, g: 0, b: 0 }
			} as DeltaRegionJSON

			return [
				...(previous ?? []),
				newTrace
			]
		})
	}

    const handleColorChange = (traceId: string, color: ColorRGB) => {
		const newTraces = [...(currentGraph?.traces ?? [])].map(trace => {
			if (trace.id === traceId) {
				return {
					...trace,
					color
				}
			}
			return trace
		})

		updateGraphProperty("traces", newTraces)
	}


	function toggleColorPicker(event: any, deltaRegionId: string) {
		const node = colorPickerRef.current

		if (node?.style.display === "block" && deltaRegionId === colorPickerDeltaRegionId) {
			node.style.display = "none"
			return
		}

		setColorPickerDeltaRegionId(deltaRegionId)

		if (node) {
			node.style.display = "block"
			node.style.top = `${event.target.getBoundingClientRect().bottom}px`
			node.style.left = `${event.target.getBoundingClientRect().right}px`
		}
	}

	useEffect(() => {
		const clickHandler = (event: any) => {
			let clickedOutside = true

			colorPickerBlockRefs.current.forEach(node => {
				if (node?.contains(event.target)) {
					clickedOutside = false
				}
			})

			if (colorPickerRef.current?.contains(event.target)) {
				clickedOutside = false
			}

			if (clickedOutside && colorPickerRef.current) {
				colorPickerRef.current.style.display = "none"
			}
		}

		document.addEventListener("click", clickHandler)
		return () => document.removeEventListener("click", clickHandler)
	})

	const availableTraces = [...(currentGraph?.traces ?? [])].filter(trace => trace.renderStrategy !== RenderStrategy.DELTA) as Array<LineTraceConfig | HeatmapTraceConfig>

	if (availableTraces.length < 2) {
		return (
			<MobergText>
				There need to be at least 2 traces configured before a delta region can be drawn.
			</MobergText>
		)
	}

    return (
        <MobergColumn gap="32px">
            <div style={{ display: "grid", gridTemplateColumns: "auto auto auto 1fr", columnGap: "32px", rowGap: "8px", padding: "0 16px" }}>
				<MobergInputLabel text={"First"} />
				<MobergInputLabel text={"Second"} />
				<MobergInputLabel text={"Color"} />
				<MobergInputLabel text={""} />

                {activeDeltaRegions.map(deltaRegion => {
					return (
                        <>
                            <MobergDropdown
								options={availableTraces.map(trace => ({ label: trace.name, value: trace }))}
								onChange={traceConfig => updateDeltaRegion(deltaRegion.id, { ...deltaRegion, first: traceConfig })}
								selectedValue={deltaRegion.first}
								equals={(a, b) => a.id === b.id}
								width={175}
							/>

                            <MobergDropdown
								options={availableTraces.map(trace => ({ label: trace.name, value: trace }))}
								onChange={traceConfig => updateDeltaRegion(deltaRegion.id, { ...deltaRegion, second: traceConfig })}
								selectedValue={deltaRegion.second}
								equals={(a, b) => a.id === b.id}
								width={175}
							/>

                            <MobergRow>
								<div
									onClick={event => toggleColorPicker(event, deltaRegion.id)}
									style={{
										width: "25px",
										height: "25px",
										backgroundColor: deltaRegion.color ? `rgb(${deltaRegion.color.r}, ${deltaRegion.color.g}, ${deltaRegion.color.b})` : "#000",
										border: "1px solid #000",
										borderRadius: "25px",
										cursor: "pointer",
									}}
									ref={ref => addColorPickerBlockRef(ref, deltaRegion.id)}
								/>
							</MobergRow>
                            
							<MobergButton shape={MobergButtonShape.SQUARE} onClick={() => removeTrace(deltaRegion.id)} style={{ padding: "6px" }}>
								<MdClose size={MobergIconSize.REGULAR} />
							</MobergButton>
                        </>
                    )})
                }

                <MobergButton onClick={addDelta} theme={MobergTheme.BLUE} variant={MobergButtonVariant.OUTLINE} style={{ width: "175px" }}>
                    Add delta region
                </MobergButton>
            </div>

            <div id={`DeltaColorPickerWindow`} ref={colorPickerRef} style={{ position: "fixed", display: "none", zIndex: 999 }}>
				<ChromePicker
					color={activeDeltaRegions.find(deltaRegion => deltaRegion.id === colorPickerDeltaRegionId)?.color ?? "#000"}
					onChange={color => {
						if (colorPickerDeltaRegionId !== undefined) {
							handleColorChange(colorPickerDeltaRegionId, color.rgb)
						}
					}}
				/>
			</div>
        </MobergColumn>
    )
}
