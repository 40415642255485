import { createContext, useCallback, useContext, useEffect, useState } from "react";


const TabContext = createContext({
    selectedTab: null,
    setSelectedTab: null
})

export function Tabs(props) {
    const { 
        children,
        initialTab
    } = props

    const [context, setContext] = useState({
        selectedTab: null,
        setSelectedTab: null
    })
    const [selectedTab, setSelectedTab] = useState(initialTab)

    useEffect(() => {
        setContext({
            selectedTab: selectedTab,
            setSelectedTab: setSelectedTab
        })
    }, [selectedTab, setSelectedTab])

    return (<TabContext.Provider value={context}>
        {children}
    </TabContext.Provider>)
}

export function ControlledTabs(props) {
    const { 
        children,
        selectedTab,
        setSelectedTab
    } = props

    const [context, setContext] = useState({
        selectedTab: selectedTab,
        setSelectedTab: setSelectedTab
    })

    useEffect(() => {
        setContext({
            selectedTab: selectedTab,
            setSelectedTab: setSelectedTab
        })
    }, [selectedTab, setSelectedTab])

    return (<TabContext.Provider value={context}>
        {children}
    </TabContext.Provider>)
}


/**
 * 
 * @returns {[any, function(): void]}
 */
export function useTabs () {
    const {selectedTab, setSelectedTab} = useContext(TabContext)
    return [selectedTab, setSelectedTab]
}

export function TabButton (props) {
    const { tabKey, children, ...rest } = props
    const [selectedTab, setSelectedTab] = useTabs()
    const handleButton = useCallback(()=>setSelectedTab(tabKey), [tabKey, setSelectedTab])
    return (
        <button onMouseDown={handleButton} selected={tabKey===selectedTab} {...rest}>{children}</button>
    )
}

export function Tab (props) {
    const {tabKey, children} = props
    const [selectedTab, setSelectedTab] = useTabs()
    
    return tabKey === selectedTab ? children : null
}