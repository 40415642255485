import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_LINKS } from '../../Constants/FrontendLinks';

function SubpageTabs({ UID, patient, currentTab }) {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState(currentTab ? currentTab : 'Data')

    function nav(tab) {
		if (!UID || selectedTab === tab) return

		if (tab === "Forms") {
            setSelectedTab('Forms')
			const navigationURL = `${FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.FORMS.SUBPAGES.PATIENT.MAIN}?patient_id=${UID}`
			navigate(navigationURL, { state: patient })
		} else if (tab === "Data") {
            setSelectedTab('Data')
			const navigationURL = `${FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.SUBPAGES.PATIENTS.MAIN}?patient_id=${UID}`
			navigate(navigationURL, { state: patient })
		} else if (tab === "Files") {
            setSelectedTab('Files')
			const navigationURL = `${FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.FILES.SUBPAGES.PATIENT.MAIN}?patient_id=${UID}`
			navigate(navigationURL, { state: patient })
		} else if (tab === 'Uploads') {
            setSelectedTab('Uploads')
			const navigationURL = `${FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.UPLOAD.SUBPAGES.PATIENT.MAIN}?patient_id=${UID}`
			navigate(navigationURL, { state: patient })
		} else {
            setSelectedTab('Data')
            alert('Page does not exist')
            navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
        }
	}

    return (
        <TabsContainer>
            <Tab isSelected={selectedTab === 'Files'} onMouseDown={() => nav('Files')}>
                <TabLabel>Files</TabLabel>
            </Tab>
            <Tab isSelected={selectedTab === 'Forms'} onMouseDown={() => nav('Forms')}>
                <TabLabel>Forms</TabLabel>
            </Tab>
            <Tab isSelected={selectedTab === 'Uploads'} onMouseDown={() => nav('Uploads')}>
                <TabLabel>Uploads</TabLabel>
            </Tab>
            <Tab isSelected={selectedTab === 'Data'} onMouseDown={() => nav('Data')}>
                <TabLabel>Data</TabLabel>
            </Tab>
        </TabsContainer>
    );
}

export default SubpageTabs;

const TabsContainer = styled.div`
    height: 47px;
    background: white;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: right;
    align-items: end;
`;

const Tab = styled.div`
    width: fit-content;
    height: 27px;
    border: 1px solid black;
    border-radius: 6px 6px 0px 0px;
    position: relative;
    bottom: -1px;
    margin-right: 5px;
    min-width: 100px;
    text-align: center;
    background: ${props => props.isSelected ? '#F8F8F8' : 'white'};
    border-width: ${props => props.isSelected ? '1px 1px 0px 1px' : '1px'};
    cursor: ${props => props.isSelected ? 'auto' : 'pointer'};
    pointer-events: ${props => props.isSelected ? 'none' : 'auto'};

    &:last-of-type {
        margin-right: 30px;
    }
`;

const TabLabel = styled.p`
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 16px;
    color: black;
`