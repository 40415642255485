import React, { createContext, useContext, useState } from "react"
import {
	MobergButton,
	MobergColumn,
	MobergRow,
	MobergTheme,
	getColors,
} from "../../../../../Moberg"
import { SourceHeatmapModalities } from "./SourceHeatmapModalities"
import { AnalyticHeatmapModalities } from "./AnalyticHeatmapModalities"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import { CompositePartTraceConfigJSON, HeatmapTraceConfig, HeatmapTraceConfigJSON, TraceConfigJSON } from "../../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { ColorSpectrum } from "../../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum"
import { TabCount } from "../LineGraphModalities/TabCount"

type HeatmapGraphModalitiesContextValue = {
	activeSourceTraces: HeatmapTraceConfig[]
	activeAnalyticTraces: HeatmapTraceConfig[]
	handleColorSpectrumChange: (colorSpectrum: ColorSpectrum, traceConfig: HeatmapTraceConfigJSON) => void
	handleValidationErrorChange: (key: string, error: string | undefined) => void
	handleDelete: (id: string) => void
	updateTrace: (traceId: string, config: HeatmapTraceConfigJSON) => void
}

export const HeatmapGraphModalitiesContext = createContext<HeatmapGraphModalitiesContextValue>({} as HeatmapGraphModalitiesContextValue)

export const HeatmapGraphModalities: React.FC = () => {
	const tabs = ["Source data", "Analytics"]
	const [selectedTab, setSelectedTab] = useState(tabs[0])

	const { activeSourceTraces, activeAnalyticTraces, currentGraph, updateGraphProperty, setValidationErrors, removeTrace } = useContext(ConfigureWindowModalContext)

	const TabContent = () => {
		switch(selectedTab) {
			case tabs[0]:
				return <SourceHeatmapModalities />
			case tabs[1]:
				return <AnalyticHeatmapModalities />
			default:
				throw new Error("Tab section not implemented! " + selectedTab)
		}
	}

	const handleValidationErrorChange = (key: string, error: string | undefined) => {
		setValidationErrors(previous => {
			const copy = new Map(previous)

			if (copy.has(key) && error === undefined) {
				copy.delete(key)
			} else if (error !== undefined) {
				copy.set(key, error)
			}

			return copy
		})
	}

	const updateTrace = (traceId: string, config: HeatmapTraceConfigJSON) => {
        const newTraces: TraceConfigJSON[] = [...(currentGraph?.traces ?? [])].map(trace => {
        const newTrace = {...trace, ...config}

            if (config.isCompositePart && config.compositeIndex !== undefined) {
                (newTrace as CompositePartTraceConfigJSON).compositeIndex = config.compositeIndex
            }
    
            // This optional property should be removed if the the modality changed and the new trace does not have an analysis.
            if (config.name && !config.onDemandAnalysis) {
                newTrace.onDemandAnalysis = undefined
            }

            if (traceId === trace.id) {
                return newTrace
            }

            return trace
        })

        updateGraphProperty("traces", newTraces)
    }

	const handleColorSpectrumChange = (colorSpectrum: ColorSpectrum, traceConfig: HeatmapTraceConfigJSON) => {
		updateTrace(traceConfig.id, {...traceConfig, colorSpectrum })
	}

	const handleDelete = (id: string) => {
		removeTrace(id)

		// When we delete the component, we have to remove the validation errors that belonged to these components.
		handleValidationErrorChange(`${id}-lowerBound`, undefined)
		handleValidationErrorChange(`${id}-upperBound`, undefined)
	}
	
	return (
		<MobergColumn gap="16px">
			<MobergRow gap="16px" style={{ borderRadius: "6px", padding: "6px", background: getColors(MobergTheme.BLUE).noFill.hover }}>
				{tabs.map(tab => {
					if (tab === selectedTab) {
						return (
							<MobergButton theme={MobergTheme.ON_LIGHT_BLUE} active={true}>
								{tab}
								<TabCount tab={tab} />
							</MobergButton>
						)
					}
					return (
					<MobergButton theme={MobergTheme.ON_LIGHT_BLUE} onClick={() => setSelectedTab(tab)}>
						{tab}
						<TabCount tab={tab} />
					</MobergButton>
					)
				})}
			</MobergRow>

			<HeatmapGraphModalitiesContext.Provider value={{ 
				activeSourceTraces: activeSourceTraces as HeatmapTraceConfig[], 
				activeAnalyticTraces: activeAnalyticTraces as HeatmapTraceConfig[], 
				handleDelete, handleColorSpectrumChange, handleValidationErrorChange, updateTrace }}>
				{TabContent()}
			</HeatmapGraphModalitiesContext.Provider>

		</MobergColumn>
	)
}