import { DragBehavior, ScaleLinear, axisLeft, Selection, EnterElement, D3DragEvent, drag } from "d3"
import { D3DragOverlay } from "./D3DragOverlay"
import { ReactCallbacks } from "../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "./D3OneToOneRenderable"

export type D3ValueAxisConfig = {
	graphId: string
	scale: ScaleLinear<any, any, any>
	label?: string
	onDrag?: () => void
	onDoubleClick?: () => void
	onDragEnd?: () => void
	onDragStart?: () => void
}

export class D3ValueAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3ValueAxisConfig, ReactCallbacks<any>> {
	private d3AxisClassName: string = "d3-axis-left"
	private labelClassName: string = "d3-value-axis-label"

	private dragOverlay?: D3DragOverlay
	private width: number = 30
	private verticalExtension: number = 10
	private dragBehavior: DragBehavior<any, any, any>

	constructor(root: SVGGElement, config: D3ValueAxisConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-value-axis", reactCallbacks)

		this.dragBehavior = drag()
			.on("start", this.onDragStart)
			.on("drag", this.onDrag)
			.on("end", this.onDragEnd)

		this.render()
	}

	public enter = (enterElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> => {
		const axisGroup = enterElements
			.append("g")
			.attr("class", this.className)

		axisGroup.append("g")
			.attr("class", this.d3AxisClassName)
			.call(axisLeft(this.config.scale).ticks(4))
			.style("user-select", "none") // disables highlighting the ticks

		// create the label
        axisGroup.append("text")
            .attr("text-anchor", "middle")
            .attr("class", this.labelClassName)
            .attr("transform", `translate(-40, ${this.config.scale.range()[0] / 2 }), rotate(-90)`)
            .text(() => this.config.label ?? "")

		axisGroup.each((config, i, nodes) => this.createChildren(config, i, nodes))

		return axisGroup
	}

	public update = (updateElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const d3AxisLeft = updateElements.select("." + this.d3AxisClassName) as Selection<SVGGElement, any, any, any>
		d3AxisLeft.call(axisLeft(this.config.scale).ticks(4))

		// update the label
        updateElements.select("." + this.labelClassName)
            .attr("transform", `translate(-40, ${this.config.scale.range()[0] / 2 }), rotate(-90)`)

		this.renderChildren()
		return updateElements
	}

	private onDrag = (dragEvent: D3DragEvent<any, any, any>) => {
		const min = this.config.scale.domain()[0]
		const max = this.config.scale.domain()[1]
		const height = this.config.scale.range()[0] - this.config.scale.range()[1]

		const range = max - min
		const sensitivity = range / height
		let { dx, dy } = dragEvent

		const pointerY = dragEvent.y + dy
		const zoomScaling = pointerY / height

		const newMinY = min + dy * sensitivity + 0.5 * (dx * sensitivity) * (1 - zoomScaling)
		const newMaxY = max + dy * sensitivity - 0.5 * (dx * sensitivity) * zoomScaling

		this.config.scale.domain([newMinY, newMaxY])

		if (this.config.onDrag) {
			this.config.onDrag()
		}

		requestAnimationFrame(() => {
			this.render()
		})
	}

	private onDragEnd = () => {
		if (this.config.onDragEnd) {
			this.config.onDragEnd()
		}
	}

	private onDragStart = () => {
		if (this.config.onDragStart) {
			this.config.onDragStart()
		}
	}

	protected updateDerivedState(): void {
		this.updateChildren()
	}

	protected createChildren(config: D3ValueAxisConfig, index: number, nodes: ArrayLike<SVGGElement>): void {
		const root = nodes[index]
		const height = config.scale.range()[0]
		const boundingBox = { x: -this.width, y: -this.verticalExtension, width: this.width, height: height + 2 * this.verticalExtension }
		this.dragOverlay = new D3DragOverlay(root, { boundingBox, dragBehavior: this.dragBehavior }, this.reactCallbacks, { onDoubleClick: this.config?.onDoubleClick })
	}

	protected updateChildren = () => {
		const height = this.config.scale.range()[0]
		const boundingBox = { x: -this.width, y: -this.verticalExtension, width: this.width, height: height + 2 * this.verticalExtension }
		this.dragOverlay?.updateConfig({boundingBox, dragBehavior: this.dragBehavior })
	}

	protected renderChildren = () => {
		this.dragOverlay?.render()
	}
}
