import React, { useState } from "react";
import {Link, useLocation} from "react-router-dom";
import styled from "styled-components";
import "@fontsource/montserrat";
import { useAuthProvider } from "../../Providers/AuthProvider";

const SubMenu = (props) => {
	const [subnav, setSubnav] = useState(true);
	const showSubnav = () => setSubnav(!subnav);
	const location = useLocation();
	const auth = useAuthProvider()

    if (props.item.subNav.filter(item => auth.permissions && auth.permissions[item['action_id']]).length === 0)  {
		return (<></>)
	}

	return (
		<>
			<hr
				style={{
					color: "#000000",
					backgroundColor: "#2f49a566",
					height: 0.5,
					borderColor: "#2f49a566",
					opacity: 0.4,
                    marginTop: '11px'
				}}
			/>
			<SidebarLink
				to={`${location.pathname}`} //${location.search}
				onMouseDown={props.item.subNav && showSubnav}
			>
				<div>
					{props.item.icon}
					<SidebarLabel>{props.item.title}</SidebarLabel>
				</div>
				<div>
					{props.item.subNav && subnav
						? props.item.iconOpened
						: props.item.subNav
						? props.item.iconClosed
						: null}
				</div>
			</SidebarLink>
			{subnav && 
				props.item.subNav.map((item, index) => {
                    if (auth.permissions === undefined || !auth.permissions[item.action_id]) return null
					return (!item.sub_page &&
						<div key={item.title}>
							<DropdownLink
								to={item.path}
								key={index}
								style={
									item.index === props.linkActiveIndex
										? {
                                            background: "#77b5ef",
                                            opacity: 1,
                                            cursor: "pointer",
                                            color: "#fff",
										  }
										: {}
								}
							>
								{item.icon}
								<SidebarLabel>{item.title}</SidebarLabel>
							</DropdownLink>
						</div>
					);
				})}
		</>
	);
};

export default SubMenu;


const SidebarLink = styled(Link)`
	display: flex;
	color: #e1e9fc;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	list-style: none;
	height: 48px;
	text-decoration: none;
	font-family: "Montserrat";
	font-weight: bold;
	color: #c1e8ff;
	font-size: 14px;
	letter-spacing: 0.08rem;

	&:hover {
		cursor: pointer;
		transition: 100ms;
		color: white;
	}
`;

const SidebarLabel = styled.span`
	margin-left: 10px;
`;

const DropdownLink = styled(Link)`
	height: 48px;
	padding-left: 3rem;
	display: flex;
	align-items: center;
	font-family: "Montserrat";
	letter-spacing: 0.08rem;
	line-height: 1.5;
	text-decoration: none;
	font-weight: bold;
	color: #fff;
	font-size: 14px;

	&:hover {
		background: #77b5ef;
		opacity: 1;
		cursor: pointer;
		transition: 100ms;
		color: #fff;
	}
`;

