export function medianDifference(arr: number[]): number {
    if (arr.length < 2) {
        throw new Error("Array must have at least two elements to calculate differences.");
    }

    // Calculate differences between successive elements
    const differences = arr.slice(1).map((num, i) => Math.abs(num - arr[i]));

    // Sort the differences
    differences.sort((a, b) => a - b);

    // Find the median of the sorted differences
    const mid = Math.floor(differences.length / 2);
    return differences.length % 2 !== 0 
        ? differences[mid] 
        : (differences[mid - 1] + differences[mid]) / 2;
}