import React, { createContext, useContext, useState } from "react"
import { MobergRow, MobergButton, MobergTheme, MobergColumn, getColors } from "../../../../../Moberg"
import { SourceDataLineModalities } from "./SourceDataLineModalities"
import { AnalyticLineModalities } from "./AnalyticLineModalities"
import { DeltaModalities } from "./DeltaModalities"
import { CompositePartTraceConfigJSON, LineTraceConfig, LineTraceConfigJSON, RenderStrategy, TraceConfigJSON } from "../../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import { TabCount } from "./TabCount"

type LineGraphModalitiesContextValue = {
	activeSourceTraces: LineTraceConfig[]
	activeAnalyticTraces: LineTraceConfig[]
	updateTrace: (id: string, newValue: LineTraceConfigJSON) => void
	handleColorChange: (traceId: string, color: string) => void
}

export const LineGraphModalitiesContext = createContext<LineGraphModalitiesContextValue>({} as LineGraphModalitiesContextValue)

export const LineGraphModalities: React.FC = () => {
	const { activeSourceTraces, activeAnalyticTraces, currentGraph, updateGraphProperty } = useContext(ConfigureWindowModalContext)
	const tabs = ["Source data", "Analytics", "Delta regions"]
	const [selectedTab, setSelectedTab] = useState(tabs[0])

	const TabContent = () => {
		switch(selectedTab) {
			case tabs[0]:
				return <SourceDataLineModalities />
			case tabs[1]:
				return <AnalyticLineModalities />
			case tabs[2]:
				return <DeltaModalities />
			default:
				throw new Error("Tab section not implemented! " + selectedTab)
		}
	}

	const updateTrace = (traceId: string, config: LineTraceConfigJSON) => {
		const newTraces: TraceConfigJSON[] = [...(currentGraph?.traces ?? [])].map(trace => {
			const newTrace = {
				...trace, 
				...config,
				id: traceId // Always important to make sure that the trace ID remains
			}

			if (config.isCompositePart && config.compositeIndex !== undefined) {
				(newTrace as CompositePartTraceConfigJSON).compositeIndex = config.compositeIndex
			}
	
			// This optional property should be removed if the the modality changed and the new trace does not have an analysis.
			if (config.name && !config.onDemandAnalysis) {
				newTrace.onDemandAnalysis = undefined
			}

			if (traceId === trace.id && trace.renderStrategy !== RenderStrategy.DELTA) {
				return newTrace
			} else if (trace.renderStrategy === RenderStrategy.DELTA) {
				// If the trace is updated and a delta region uses it, update its de-normalized copy.
				const delta = { ...trace }

				if (traceId === trace.first.id) {
					delta.first = {...config, id: traceId } as any
				}

				if (traceId === trace.second.id) {
					delta.second = {...config, id: traceId } as any
				}

				return delta
			}

			return trace
		})

		updateGraphProperty("traces", newTraces)
	}

	const handleColorChange = (traceId: string, color: string) => {
		const newTraces = [...(currentGraph?.traces ?? [])].map(trace => {
			if (trace.id === traceId) {
				return {
					...trace,
					color
				}
			}
			return trace
		})

		updateGraphProperty("traces", newTraces)
	}

	return (
		<MobergColumn gap="16px">
			<MobergRow gap="16px" style={{ borderRadius: "6px", padding: "6px", background: getColors(MobergTheme.BLUE).noFill.hover }}>
				{tabs.map(tab => {
					if (tab === selectedTab) {
						return (
							<MobergButton theme={MobergTheme.ON_LIGHT_BLUE} active={true}>
								{tab}
								<TabCount tab={tab} />
							</MobergButton>
						)
					}
					return (
						<MobergButton theme={MobergTheme.ON_LIGHT_BLUE} onClick={() => setSelectedTab(tab)}>
							{tab} 
							<TabCount tab={tab} />
						</MobergButton>
					)
				})}
			</MobergRow>

			<LineGraphModalitiesContext.Provider value={{ 
				activeSourceTraces: activeSourceTraces as LineTraceConfig[], 
				activeAnalyticTraces: activeAnalyticTraces as LineTraceConfig[], 
				updateTrace, handleColorChange }}>
				{TabContent()}
			</LineGraphModalitiesContext.Provider>
		</MobergColumn>
	)
}
