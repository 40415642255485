import { Selection, EnterElement, ScaleLinear, line } from "d3";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";

export type D3ErrorBarConfig = {
    x: number,
    y: number | null,
    error: number | null
    yScale: ScaleLinear<any, any, any>
    xScale: ScaleLinear<any, any, any>
    transitionDuration?: number
    dataPercentage: number | null
    includedInFit: boolean
}

export class D3ErrorBar extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3ErrorBarConfig> {
    private capSize = 5
    private connectingBarClassName = "d3-error-bar-connector"
    private bottomCapClassName = "d3-error-bar-bottom-cap"
    private ghostCircleClassName = "d3-error-bar-ghost-circle"
    private dataPercentageTextClassName = "d3-error-bar-data-percentage-text"
    private topCapClassName = "d3-error-bar-top-cap"
    private color = "#207dea"
    private disabledColor = "red"

    protected enter(newElements: Selection<EnterElement, D3ErrorBarConfig, any, any>): Selection<SVGGElement, D3ErrorBarConfig, SVGGElement, any> {
        const scaledError = this.getScaledError()

        const container = newElements.append("g")
            .attr("class", this.className)
            .attr("transform", `translate(${this.config.xScale(this.config.x)}, ${this.config.yScale(this.config.y ?? 0) + 20})`)
            
        const animatedContainer = container.transition().duration(this.config.transitionDuration ?? 0)

        // draw the data percentage helper text
        container.append("text")
            .attr("class", this.dataPercentageTextClassName)
            .attr("transform", `translate(0, ${scaledError - 15})`)
            .attr("text-anchor", "middle")
            .attr("fill", this.config.includedInFit ? this.color : this.disabledColor)
            .text(`${Math.round(this.config.dataPercentage ?? 0)}%`)

        animatedContainer
            .attr("transform", `translate(
                ${this.config.xScale(this.config.x)}, 
                ${this.config.yScale(this.config.y ?? 0)})`
            )

        // draw the top cap
        container.append("path")
            .attr("class", this.topCapClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("stroke-width", 1)
            .attr("fill", "none")
            .attr("d", line()([[-this.capSize, scaledError], [this.capSize, scaledError]]))

        // draw the line connecting line
        container.append("path")
            .attr("class", this.connectingBarClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("stroke-width", 1)
            .attr("fill", "none")
            .attr("d", line()([[0, -scaledError], [0, scaledError]]))

        // draw the middle circle
        container.append("circle")
            .attr("class", this.ghostCircleClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("stroke-width", 1)
            .attr("fill", "none")
            .attr("r", 5)

        // draw the bottom cap
        container.append("path")
            .attr("class", this.bottomCapClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("stroke-width", 1)
            .attr("fill", "none")
            .attr("d", line()([[-this.capSize, -scaledError], [this.capSize, -scaledError]]))

        return container
    }
    
    protected update(updatedElements: Selection<SVGGElement, D3ErrorBarConfig, any, any>): Selection<SVGGElement, D3ErrorBarConfig, SVGGElement, any> {
        const scaledError = this.getScaledError()
        const container = updatedElements

        const animatedContainer = container.transition().duration(this.config.transitionDuration ?? 0)

        // update the container itself
        animatedContainer
            .attr("transform", `translate(
                ${this.config.xScale(this.config.x)}, 
                ${this.config.yScale(this.config.y ?? 0)}
                )
            `)

        // update the data percentage text
        animatedContainer.select("." + this.dataPercentageTextClassName)
            .attr("transform", `translate(0, ${scaledError - 15})`)
            .attr("fill", this.config.includedInFit ? this.color : this.disabledColor)
            .text(`${Math.round(this.config.dataPercentage ?? 0)}%`)

        // update the top caps
        animatedContainer.select("." + this.topCapClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("d", line()([[-this.capSize, scaledError], [this.capSize, scaledError]]))

        // update the connecting bar
        animatedContainer.select("." + this.connectingBarClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("d", line()([[0, -scaledError], [0, scaledError]]))

        // update the ghost circle
        animatedContainer.select("." + this.ghostCircleClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)

        // update the bottom cap
        animatedContainer.select("." + this.bottomCapClassName)
            .attr("stroke", this.config.includedInFit ? this.color : this.disabledColor)
            .attr("d", line()([[-this.capSize, -scaledError], [this.capSize, -scaledError]]))

        return updatedElements
    }

    protected exit(exitedElements: Selection<any, any, SVGGElement, D3ErrorBarConfig>): void {
        exitedElements.transition()
            .duration(this.config.transitionDuration ?? 0)
            .attr("transform", `translate(${this.config.xScale(this.config.x)}, ${this.config.yScale(this.config.yScale.domain()[0])})`)
            .end()
            .then(() => {
                exitedElements.remove()
            })
    }

    private getScaledError = () => {
        let error = this.config.error ?? 0
        return this.config.yScale(error) - this.config.yScale(0)
    }
}