import { TimeBasedVisualizationConfig, TimeBasedVisualizationConfigJSON } from "./TimeBasedVisualizationConfig";

export interface CPPOptPlotJSON extends TimeBasedVisualizationConfigJSON {
	abpModality: string
	icpModality: string
}

export interface CPPOptPlotConfig extends TimeBasedVisualizationConfig {
	abpModality: string
	icpModality: string
}

export const DEFAULT_CPPOPT_PLOT_JSON: CPPOptPlotJSON = {
	abpModality: "ABP_na",
	icpModality: "ICP_na",
    viewDuration: 8 * 60 * 60 * 1000,
	playbackSpeed: 1
}

export type CPPOptData = {
	CPPOpt: number | null
	LLA: number | null
	ULA: number | null
	fitType: number | null
	fitSpan: number | null
	includedDataPercentage: number | null
	coefficients: (number | null)[]
	yValues: (number | null)[]
	errors: (number | null)[]
	dataPercentages: (number | null)[]
	includedInFit: (number | null)[]
	weight: number | null
}
