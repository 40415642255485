import React, { useContext } from "react"
import { MdClose, MdSettings } from "react-icons/md"
import {
	MobergButton,
	MobergButtonShape,
	MobergButtonVariant,
	MobergDropdown,
	MobergIconSize,
	MobergInputLabel,
	MobergNumberInput,
	MobergRow,
	MobergShortTextInput,
	MobergTheme,
} from "../../../../../Moberg"
import { ColorSpectrum } from "../../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum"
import { HeatmapTraceConfig, HeatmapTraceConfigJSON, RenderStrategy, TraceConfigJSON, TraceOption } from "../../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import { SpectrumSample } from "../SpectrumSample"
import { EditAnalysisModal } from "../../../../../Pages/Data/Visualize/DataReview/Components/Modals/EditAnalysis/EditAnalysisModal"
import { useModalProvider } from "../../../../../Providers/ModalProvider"
import { getAnalyticDisplayName, OnDemandAnalysis } from "../../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails"
import { HeatmapGraphModalitiesContext } from "./HeatmapGraphModalities"

export const AnalyticHeatmapModalities: React.FC = () => {
	const { traceOptions, updateGraphProperty, } = useContext(ConfigureWindowModalContext)
	const { activeAnalyticTraces, handleDelete, handleColorSpectrumChange, handleValidationErrorChange, updateTrace } = useContext(HeatmapGraphModalitiesContext)
	const { createModal } = useModalProvider()
    const analyticTraceOptions = traceOptions.filter((traceOption: TraceOption) => traceOption.value.onDemandAnalysis)

	const addModality = () =>
		updateGraphProperty("traces", (previous: TraceConfigJSON[]) => {
			const newTrace: HeatmapTraceConfigJSON = {
				...analyticTraceOptions[0].value,
				id: `trace-${new Date(Date.now()).toISOString()}`, // ALWAYS override the existing ID with a new, unique one.
				renderStrategy: RenderStrategy.HEATMAP,
				lowerBound: 0,
				upperBound: 100,
				colorSpectrum: ColorSpectrum.INFERNO,
			}

			return [...previous, newTrace]
		})

	function traceAnalysisArgumentsChanged(trace: HeatmapTraceConfig, newArgs: OnDemandAnalysis | undefined) {
		updateTrace(trace.id, {
            ...trace,
			onDemandAnalysis: newArgs,
			dataKey: JSON.stringify(newArgs)
		})
	}

	return (
		<div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto auto auto auto auto", columnGap: "16px", rowGap: "8px", padding: "0 16px", maxWidth: "fit-content"}}>
			<MobergInputLabel text={"Analytic"} />
            <MobergInputLabel text={"Label"} />
			<MobergInputLabel text={"Color spectrum"} />
			<MobergInputLabel text={"Lower Bound"} />
			<MobergInputLabel text={""} />
			<MobergInputLabel text={"Upper Bound"} />
			<MobergInputLabel text={"Calculation"} />
			<MobergInputLabel text={""} />

			{activeAnalyticTraces.map(trace => {
				return (
					<>
						<MobergDropdown
							key={`${trace.id}-modalitySelector`}
							options={analyticTraceOptions}
							onChange={newTrace => updateTrace(trace.id, newTrace)}
							selectedValue={trace}
							equals={(a: HeatmapTraceConfigJSON, b: HeatmapTraceConfigJSON) => 
								a.onDemandAnalysis?.analytic === b.onDemandAnalysis?.analytic
								&& (a.isCompositePart 
										? b.isCompositePart && a.compositeIndex === b.compositeIndex
										: true
									)
							}
							width={175}
						/>

                        <MobergShortTextInput
                            limit={20}
                            defaultValue={trace.name}
                            value={trace.name}
                            onChange={(name: string) => updateTrace(trace.id, { ...trace, name })}
                            style={{ width: "150px" }}
                        />

						<MobergDropdown
							key={`${trace.id}-spectrumSelector`}
							selectedValue={trace.colorSpectrum}
							onChange={spectrum => handleColorSpectrumChange(spectrum, trace)}
							options={[
								{ label: "Inferno", value: ColorSpectrum.INFERNO },
								{ label: "Viridis", value: ColorSpectrum.VIRIDIS },
								{ label: "Grays", value: ColorSpectrum.GRAYS },
								{ label: "Greens", value: ColorSpectrum.GREENS },
								{ label: "Reds", value: ColorSpectrum.REDS },
								{ label: "Red-Blue", value: ColorSpectrum.RED_BLUE },
								{ label: "Red-Yellow-Green", value: ColorSpectrum.RED_YELLOW_GREEN },
							]}
							width={175}
						/>

						<MobergNumberInput 
							key={`${trace.id}-lowerBound`}
							defaultValue={trace.lowerBound} 
							onChange={lowerBound => updateTrace(trace.id, { ...trace, lowerBound })}
							onValidationErrorChange={error => handleValidationErrorChange(`${trace.id}-lowerBound`, error)}
						/>

						<MobergRow key={`${trace.id}-spectrumSample`}>
							<SpectrumSample colorSpectrum={trace.colorSpectrum} />
						</MobergRow>

						<MobergNumberInput
							key={`${trace.id}-upperBound`}
							defaultValue={trace.upperBound}
							onChange={upperBound => updateTrace(trace.id, { ...trace, upperBound })}
							onValidationErrorChange={error => handleValidationErrorChange(`${trace.id}-upperBound`, error)}
						/>

						<MobergButton
							disabled={!trace.onDemandAnalysis}
							tooltip={trace.onDemandAnalysis 
								? "Edit the calculation of " + getAnalyticDisplayName(trace.onDemandAnalysis.analytic)
								: "This modality is a raw signal."}
							shape={MobergButtonShape.SQUARE}
							onClick={() => {
								if (trace.onDemandAnalysis?.analytic) {
									createModal(<EditAnalysisModal 
										analysis={trace.onDemandAnalysis}
										rawDataKey={trace.dataKey}
										onChange={(newArgs: OnDemandAnalysis | undefined) => traceAnalysisArgumentsChanged(trace, newArgs)}
									/>)
								}
							}}
						>
							<MdSettings size={MobergIconSize.REGULAR} />
						</MobergButton>

						<MobergButton
							key={`${trace.id}-deleteHeatmap`}
							shape={MobergButtonShape.SQUARE}
							onClick={() => handleDelete(trace.id)}
							style={{ padding: "6px" }}
							>
							<MdClose size={MobergIconSize.REGULAR} />
						</MobergButton>
					</>
				)
			})}

			<MobergButton onClick={addModality} theme={MobergTheme.BLUE} variant={MobergButtonVariant.OUTLINE} style={{ width: "175px" }}>
				Add analytic
			</MobergButton>

		</div>
	)
}
