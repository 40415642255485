import React, { useEffect, useLayoutEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import "@fontsource/montserrat";
import { SidebarData, RESOURCES } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import "semantic-ui-css/semantic.min.css";
import { useEnvironmentVariablesProvider } from "../../Providers/EnvironmentVariablesProvider";
import { useAuthProvider } from "../../Providers/AuthProvider";

const Sidebar = (props) => {
	const authProvider = useAuthProvider()
	const environmentVariablesProvider = useEnvironmentVariablesProvider()
	const SIDEBAR_TABS = useMemo(() => environmentVariablesProvider.environmentVariables.RESOURCES_TAB ? [SidebarData, RESOURCES].flat() : SidebarData, [environmentVariablesProvider.environmentVariables.RESOURCES_TAB])

	const [sidebarOpen, setsidebarOpen] = useState(true)
	const toggleSidebar = () => {
		if (sidebarOpen) {
			document.getElementById("HeaderAndPage").style.marginLeft = "40px"
		} else {
			document.getElementById("HeaderAndPage").style.marginLeft = "250px"
		}
		setsidebarOpen(!sidebarOpen)
	};

	const [linkActiveIndex, setLinkActiveIndex] = useState(1);

	function useWindowSize() {
		const [size, setSize] = useState(0);
		useLayoutEffect(() => {
			function updateSize() {
				setSize(window.innerHeight);
			}
			window.addEventListener('resize', updateSize);
			updateSize();
			return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	}

	const size = useWindowSize()
	const location = useLocation();
	useEffect(() => {
		for (let group of SIDEBAR_TABS) {
			for (let section of group['subNav']) {
				if (section.path === location.pathname) {
					setLinkActiveIndex(section.index)
				}
			}
		}
	}, [SIDEBAR_TABS, location]);

	const [enableSideBar, setEnableSideBar] = useState(true)

	useEffect(() => {
		if (authProvider.userIsLoggedIn && location.pathname === '/inactive') {
			setEnableSideBar(false)
		} else {
			setEnableSideBar(true)
		}
		if (!authProvider.userIsLoggedIn) {
			setEnableSideBar(false)
		}
	}, [authProvider.userIsLoggedIn, location.pathname])

	return (
		<div style={{ "display": enableSideBar ? "block" : "none" }}>
			<IconContext.Provider value={{ color: "#fff" }}>
				<Nav sidebar={sidebarOpen}>
					<NavIcon>
						<MdIcons.MdKeyboardArrowRight
							onMouseDown={toggleSidebar}
							style={{ cursor: "pointer" }}
						/>
					</NavIcon>
				</Nav>
				<p id='TitleClosed' style={{ bottom: '190px', transform: 'rotate(-90deg)', position: 'absolute', fontFamily: 'Montserrat', paddingRight: '37px', fontSize: '28px', marginLeft: '-156px', fontWeight: 'bolder', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', color: '#fff', opacity: '0.95', letterSpacing: '0.09rem' }} >
					<MdIcons.MdCloud size="40px" style={{
						marginBottom: '7px',
					}} />
					Moberg CONNECT

				</p>
				<SidebarNav id="sideBarNav" sidebar={sidebarOpen} scrollableSidebar={props.scrollableSidebar}>
					<SidebarWrap style={{ height: size }}>
						<NavIcon>
							<MdIcons.MdCloud size="30px" margin="20px" style={{
								marginLeft: '107px'

							}} />
							<MdIcons.MdKeyboardArrowLeft
								onMouseDown={toggleSidebar}
								style={{ marginLeft: '10px', cursor: "pointer" }}
							/>
						</NavIcon>
						<SidebarTitle>Moberg CONNECT</SidebarTitle>
						<VersionNumber style={{ color: "#FFFFFF", display: "block", marginTop: "-13px", marginBottom: "-20px", textAlign: "center", fontFamily: "Montserrat", fontSize: "12px", cursor: "pointer" }} href="/release_changelogs">V1.0.1.0</VersionNumber>
						<br></br>
						{SIDEBAR_TABS.map((item, index) => {
							return (
								<SubMenu
									key={index}
									item={item}
									setLinkActiveIndex={setLinkActiveIndex}
									linkActiveIndex={linkActiveIndex}
								/>
							);
						})}
					</SidebarWrap>
				</SidebarNav>
			</IconContext.Provider>
		</div>
	);
};

export default Sidebar;

const VersionNumber = styled.a`
	&:hover {
		text-decoration: underline;
	}
`;

const Nav = styled.div`
    position: fixed;
    top: 0px;
    background: linear-gradient(270deg, #3ea2ff, #3791e5);
    height: 100vh;
    width: 50px;
    display: ${({ sidebar }) => (sidebar ? "none" : "flex")};
    justify-content: flex-start;
    /* align-items: center; */
`;

const NavIcon = styled.div`
	margin: 15px 0.5rem 10px 2px;
	font-size: 2rem;
	height: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 45px;
	cursor: pointer;
`;

const SidebarNav = styled.nav`
    background: #15171c;
    width: 250px;
    height: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
    background: linear-gradient(270deg, #3ea2ff, #0055d4);
    overflow: scroll;
`;

const SidebarTitle = styled.div`
	width: auto;
	height: 44px;
	margin: 12px 43 0.5px 26px 1px;
	font-family: "Montserrat";
	margin: auto 10px;
	font-size: 20px;
	font-weight: bolder;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	letter-spacing: 0.09rem;
	opacity: 0.95;
	text-align: center;
`;

const SidebarWrap = styled.div`
	width: 100%;
`;