import * as MdIcons from "react-icons/md"
import { MdDelete } from "react-icons/md"
import styled from 'styled-components'
import { useState, useCallback, useEffect } from "react";
import { ControlledTabs } from "../../../../Components/Tabs/Tabs";
import { Column } from "../../../../Components/Layout/Column";
import { TabSelector } from "../../../../Components/Tabs/TabSelector";
import { Modes } from "../../../../Components/CustomAnnotationHotkeys/CustomAnnotationHotkeys";
import { Annotation } from "../../Variables/Annotations";
import { DEFAULT_ANNOTATION_PROPERTIES } from "../../Variables/Annotations";
import { CheckboxButton } from "../../../../Components/CheckboxButton/CheckboxButton";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { useHotkeysProvider } from "../../../../Providers/HotkeysProvider";
import ResetToDefaultModal from "./ConfirmationModals/ResetToDefaultModal";
import DeleteKeyboardShortcutModal from "./ConfirmationModals/DeleteKeyboardShortcutModal";
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader";
import { useSetRecoilState, useRecoilState } from "recoil"
import { hotkeyActions, hotkeySections, hotkeyTabs } from "../../../../Pages/Data/Visualize/DataReview/Types/KeyboardShortcut";
import { hotkeysAtom, hotkeysEnabledAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys";
import { selectedAnnotationAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { EditAnnotation } from "../Components/EditAnnotation";
import { authUpdates, useAuthProvider } from "../../../../Providers/AuthProvider";
import { useOnMount } from "../../../../Hooks/useOnMount";
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors";
import { MobergFontSize } from "../../../../Components/MobergFont/MobergFont";
import { MobergIconSize } from "../../../../Components/MobergIcon/MobergIcon";

export function HotkeysModal({ updateHotkeyConfig, resetHotkeyConfig }) {
    const { createModal, close } = useModalProvider()
    const hotkeysProvider = useHotkeysProvider()
    const authProvider = useAuthProvider()

    useOnMount(() => {
        authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS)
    })

    const [selectedTab, setSelectedTab] = useState(hotkeyTabs.GENERAL)
    const setHotkeysEnabled = useSetRecoilState(hotkeysEnabledAtom)
    const [hotkeys, setHotkeys] = useRecoilState(hotkeysAtom)

    const [uneditedHotkeys, __setUneditedHotkeys] = useState(structuredClone(hotkeys))

    const setUneditedHotkeys = useCallback((s) => {  // never call __setUneditedHotkeys directly because of shallow copy
        __setUneditedHotkeys(structuredClone(s))
    }, [__setUneditedHotkeys])

    const [hotkeyConfigs, setHotkeyConfigs] = useState(structuredClone(hotkeys).map(item => {
        const updatedData = {
            ...item.data, // Copy existing data properties
            tags: item.data ? item.data["tags"] : [],
            description: item.data ? item.data["description"] : "",
        }
        return {
            ...item,
            data: updatedData,
            isInEditMode: false,
            editModeWarning: false
        };
    }))

    const [newAnnotationNumber, setNewAnnotationNumber] = useState(0)
    const handleAnnotationHotkeySave = useCallback((data, mode) => {
        switch (mode) {
            case Modes.EDIT:
                setHotkeyConfigs(previous => {
                    const configsCopy = structuredClone(previous)
                    const newConfigs = configsCopy.map(config => {
                        if (config.id === data.id) {
                            const newConfig = structuredClone(config)
                            newConfig['triggers'] = data?.triggers
                            
                            const newData = structuredClone(data)
                            delete newData?.triggers
                            newConfig['data'] = { ...newConfig['data'], ... newData }
                            
                            return newConfig
                        }
                        return structuredClone(config)
                    })

                    updateHotkeyConfig(newConfigs, (s) => {
                        setUneditedHotkeys(s)
                        setHotkeyConfigs(structuredClone(s))
                        setHotkeys(s) //updates visualization context value
                        setNewAnnotationNumber(0)
                    })

                    return newConfigs
                })
                break
            case Modes.CREATE:
                setHotkeyConfigs(previous => {
                    const newAnnotation = {
                        action: hotkeyActions.SET_NEW_ANNOTATION_PROPERTIES,
                        editable: true,
                        data: data,
                        id: "ann_new_" + newAnnotationNumber,
                        section: hotkeySections.CUSTOM_ANNOTATIONS,
                        tab: hotkeyTabs.ANNOTATIONS,
                        triggers: data.triggers,
                        deletable: true,
                        isInEditMode: false,
                        editModeWarning: false
                    }
                    newAnnotation.data.id = newAnnotation.id
                    setNewAnnotationNumber(newAnnotationNumber + 1)

                    updateHotkeyConfig([...structuredClone(previous), newAnnotation], (s) => {
                        setUneditedHotkeys(s)
                        setHotkeyConfigs(structuredClone(s))
                        setHotkeys(s) //updates visualization context value
                        setNewAnnotationNumber(0)
                    })

                    return [...structuredClone(previous), newAnnotation]
                }
                )
                break
            default:
                return
        }
    }, [newAnnotationNumber, setNewAnnotationNumber, setHotkeyConfigs])

    const selectedConfigs = hotkeyConfigs.filter(config => config.tab === selectedTab)
    const selectedSections = [...new Set(selectedConfigs.map(config => config.section))]
    const allSectionNames = Object.values(hotkeySections)

    // Sort by the way the sections appear in "hotkeySections"
    selectedSections.sort((sectionA, sectionB) => allSectionNames.indexOf(sectionA) - allSectionNames.indexOf(sectionB))

    const [isGeneral, setIsGeneral] = useState(true) // useState handles displaying/hiding Annotations buttons (Add new shortcut, Edit, trash, Reset to default)

    const [selectedAnnotations, setSelectedAnnotations] = useState([]) // useState is an array of currently checked annotation titles/names

    function onCheckAnnotation(annotation, nextState) {
        if (nextState) {
            setSelectedAnnotations(prevState => [...prevState, annotation.id])
        }
        else {
            setSelectedAnnotations(prevState => prevState.filter(selectedAnnotation => selectedAnnotation !== annotation.id))
        }
    }

    useEffect(() => {
        let existingAnnotations = hotkeyConfigs.map(config => config.data?.id).flat(1).filter(key => key != null)
        setSelectedAnnotations(prevState => prevState.filter(selectedAnnotation => existingAnnotations.includes(selectedAnnotation)))

    }, [hotkeyConfigs])

    function handleRemoveAnnotations() {
        setHotkeyConfigs(prevState => {
            const newConfigs = structuredClone(prevState).filter(annotation =>
                annotation.deletable ?
                    !(selectedAnnotations.includes(annotation.id)) :
                    annotation
            )

            updateHotkeyConfig(newConfigs, (s) => {
                setUneditedHotkeys(s)
                setHotkeyConfigs(structuredClone(s))
                setHotkeys(s) //updates visualization context value
                setNewAnnotationNumber(0)
            })

            return newConfigs
        })
    }

    async function handleRemoveAnnotationFromEditorAndClose(selectedShortcut) {
        await handleRemoveAnnotationFromEditor(selectedShortcut)
        // First close will close the confirmation modal. The second closes the main modal.
        await close()
        await close()
    }

    async function handleRemoveAnnotationFromEditor(selectedShortcut) {
        setHotkeyConfigs(prevState => {
            const newConfigs = structuredClone(prevState).filter(annotation =>
                annotation.deletable ?
                    annotation.id !== selectedShortcut.id :
                    annotation
            )

            updateHotkeyConfig(newConfigs, (s) => {
                setUneditedHotkeys(s)
                setHotkeyConfigs(structuredClone(s))
                setHotkeys(s) //updates visualization context value
                setNewAnnotationNumber(0)
            })

            return newConfigs
        })
    }

    function renderResetToDefaultModal() {
        createModal(
            <ResetToDefaultModal
                escClose={false}
                clickOutClose={false}
            />
        )
    }

    function renderDeleteAnnotationModal() {
        createModal(
            <DeleteKeyboardShortcutModal
                escClose={false}
                clickOutClose={false}
            />
        )
    }

    function exitShortcutsModalNoChanges() {
        setHotkeysEnabled(true)
        close()
    }

    // for Custom Annotation (Hotkey) Modal
    const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)

    useEffect(() => {
        if (hotkeysProvider.resetToDefault) {

            resetHotkeyConfig((s) => {
                setUneditedHotkeys(s)
                setHotkeyConfigs(s)
                setHotkeys(s) //updates visualization context value
            })
            hotkeysProvider.setResetToDefault(false)
            return
        } else if (hotkeysProvider.deleteAnnotations) {
            handleRemoveAnnotations()
            hotkeysProvider.setDeleteAnnotations(false)
            return
        } else {
            return
        }
    }, [hotkeysProvider.resetToDefault, hotkeysProvider.deleteAnnotations, hotkeysProvider, resetHotkeyConfig, setUneditedHotkeys, setHotkeys])

    useEffect(() => {
        if (hotkeysProvider.deleteAndExit || hotkeysProvider.cancelChanges) {
            setHotkeysEnabled(true)
            setSelectedAnnotations([])
            setNewAnnotationNumber(0)
            const updatedArray = uneditedHotkeys.map(item => {
                return {
                    ...structuredClone(item),
                    isInEditMode: false,
                    editModeWarning: false
                };
            });
            setHotkeyConfigs(updatedArray)
            if (hotkeysProvider.deleteAndExit) {
                close()
            }
            hotkeysProvider.setCancelChanges(false)
            hotkeysProvider.setDeleteAndExit(false)
            return
        } else if (hotkeysProvider.saveAndExit || hotkeysProvider.saveChanges) {
            setHotkeysEnabled(true)
            setSelectedAnnotations([])
            updateHotkeyConfig(hotkeyConfigs, (s) => {
                setUneditedHotkeys(s)
                setHotkeyConfigs(structuredClone(s))
                setHotkeys(s) //updates visualization context value
                setNewAnnotationNumber(0)
            })

            if (hotkeysProvider.saveAndExit) {
                close()
            }
            hotkeysProvider.setSaveChanges(false)
            hotkeysProvider.setSaveAndExit(false)
        } else {
            return
        }
    }, [hotkeysProvider.deleteAndExit, hotkeysProvider.saveAndExit, hotkeysProvider.cancelChanges, hotkeysProvider.saveChanges])

    function getPrettyHotkeyTrigger(config) {
        if (config.triggers[0] === null) {
            return null
        }

        const trigger = config.triggers[0].toLowerCase()

        switch (trigger) {
            case "arrowright": return <MdIcons.MdArrowRightAlt />
            case "arrowleft": return <MdIcons.MdArrowRightAlt style={{ transform: "rotate(180deg)" }} />
            case "arrowdown": return <MdIcons.MdArrowRightAlt style={{ transform: "rotate(90deg)" }} />
            case "arrowup": return <MdIcons.MdArrowRightAlt style={{ transform: "rotate(270deg)" }} />
            case "escape": return "Esc"
            case " ": return "spacebar"
            default: return trigger
        }
    }

    function renderEditAnnotationModal(config, mode) {
        if (mode === 'CREATE') {
            config.isInEditMode = true
            config.triggers = [null, null]
        }

        createModal(
            <EditAnnotation
                escClose={false}
                clickOutClose={false}
                config={config}
                mode={mode}
                isHotkeysModal={true}
                onSave={(data) => { handleAnnotationHotkeySave(data, mode) }}
                onDelete={(data) => { handleRemoveAnnotationFromEditorAndClose(data) }}
                hotkeyConfigs={hotkeyConfigs}
                setHotkeyConfigs={setHotkeyConfigs}
            />
        )
    }

    useEffect(() => {
        if (selectedTab !== "General") {
            setIsGeneral(false)
        } else {
            setIsGeneral(true)
        }
    }, [selectedTab])

    function openCustomAnnotationModal(config, mode) {
        setSelectedAnnotation(Annotation.fromConfig(config))
        renderEditAnnotationModal(config, mode)
    }

    return (
        <div style={{ overflow: "visible" }}>
            <div style={{ width: "600px", maxHeight: "650px", background: "#FFFFFF", borderRadius: "6px", border: "none", zIndex: "950px", display: 'flex', flexDirection: 'column', minHeight: '500px' }}>
                <ModalHeader headerText="Keyboard Shortcuts" hrStyle={{ display: "none" }}
                    closeFxn={exitShortcutsModalNoChanges}
                />

                <div style={{ paddingLeft: "15px" }}>
                    <ControlledTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
                        <Column style={{ paddingLeft: "15px" }}>
                            <TabSelector tabKeys={Object.values(hotkeyTabs)} style={{ color: "#5A6679" }} permission={authProvider.permissions?.create_annotations} />
                        </Column>
                    </ControlledTabs>
                </div>

                <hr style={{ border: "1px solid rgb(41, 50, 65)", margin: 0 }} />

                <ModalContentVertical>
                    <div style={{ height: "40px", marginBottom: "16px", display: isGeneral ? "none" : "flex" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                            <MobergButton disabled={hotkeyConfigs.some(config =>
                                config.section === 'Custom Annotations' &&
                                (config.isInEditMode === true || config.editModeWarning === true)
                            )} theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} fontSize={MobergFontSize.LARGE}
                                onClick={() => { openCustomAnnotationModal(DEFAULT_ANNOTATION_PROPERTIES, Modes.CREATE) }}>
                                Add new shortcut
                            </MobergButton>

                            <div style={{ display: "flex", gap: "8px" }}>
                                <MobergButton
                                    theme={MobergTheme.RED}
                                    variant={MobergButtonVariant.FILLED}
                                    shape={MobergButtonShape.SQUARE}
                                    disabled={selectedAnnotations.length === 0}
                                    onClick={renderDeleteAnnotationModal}>
                                    <MdDelete size={MobergIconSize.REGULAR} />
                                </MobergButton>

                                <MobergButton
                                    theme={MobergTheme.BLUE}
                                    variant={MobergButtonVariant.OUTLINE}
                                    shape={MobergButtonShape.SQUARE}
                                    fontSize={MobergFontSize.LARGE}
                                    onClick={renderResetToDefaultModal}>
                                    Reset to default
                                </MobergButton>
                            </div>
                        </div>
                    </div>
                </ModalContentVertical>

                {(!isGeneral && !selectedSections.includes('Custom Annotations')) ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                        <h1>
                            No Shortcuts
                        </h1>
                    </div>
                : null}

                {selectedSections.map(section => {
                    if (section === hotkeySections.NAVIGATION) {
                        if (!isGeneral) {
                            setIsGeneral(true)
                            setSelectedAnnotations([])
                        }

                        let generalConfigs = hotkeyConfigs.filter(config => (config.section === "Basic"))
                        return (
                            <ShortcutsContainer style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                {selectedConfigs.map((config) => {
                                    return (
                                        <AnnotationDiv title={!authProvider.permissions?.create_annotations && config.title.toLowerCase().includes('annotation') ? 'You do not have permission for this action' : ''} style={{ opacity: !authProvider.permissions?.create_annotations && config.title.toLowerCase().includes('annotation') ? 0.5 : 1 }}>
                                            <p style={{ margin: 0 }}>{config.title}</p>
                                            <KeyboardButton>
                                                {getPrettyHotkeyTrigger(config)}
                                            </KeyboardButton>
                                        </AnnotationDiv>
                                    )
                                })}

                                {generalConfigs.map((config) => {
                                    return (
                                        <AnnotationDiv title={!authProvider.permissions?.create_annotations && config.title.toLowerCase().includes('annotation') ? 'You do not have permission for this action' : ''} style={{ opacity: !authProvider.permissions?.create_annotations && config.title.toLowerCase().includes('annotation') ? 0.5 : 1 }}>
                                            <p style={{ margin: 0 }}>{config.title}</p>
                                            <KeyboardButton>
                                                {getPrettyHotkeyTrigger(config)}
                                            </KeyboardButton>
                                        </AnnotationDiv>
                                    )
                                })}

                            </ShortcutsContainer>)
                    } else if (section === hotkeySections.CUSTOM_ANNOTATIONS) {
                        if (isGeneral) {
                            setIsGeneral(false)
                        }
                        let annotationConfigs = hotkeyConfigs.filter(config => (config.section === section))
                        return (
                            <ShortcutsContainer style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                {annotationConfigs.map((config) => {
                                    return (
                                        <AnnotationDiv backgroundColor={selectedAnnotations.includes(config.id) ? "#E5F5FD" : "none"}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                                                <CheckboxButton
                                                    style={{ cursor: "pointer", marginRight: '5px' }}
                                                    state={selectedAnnotations.includes(config.id)}
                                                    action={(nextState) => onCheckAnnotation(config, nextState)}
                                                />

                                                <p title={config.data.text ? config.data.text : "Custom Annotation"} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: 0 }}>
                                                    {config.data.text ? config.data.text : "Custom Annotation"}
                                                </p>

                                                <ColorCircle style={{ marginLeft: '5px' }} color={config.data.color} opacity={config.data.opacity} />

                                                <MdIcons.MdEdit size={18} style={{
                                                    color: "#AEB7C6", cursor: "pointer", marginLeft: '3px', pointerEvents: hotkeyConfigs.some(config =>
                                                        config.section === 'Custom Annotations' &&
                                                        (config.isInEditMode === true || config.editModeWarning === true)
                                                    ) ? 'none' : 'auto'
                                                }}
                                                    onMouseDown={() => {
                                                        setSelectedAnnotation(Annotation.fromConfig(config))
                                                        renderEditAnnotationModal(config, Modes.EDIT)
                                                    }}
                                                />

                                                {/* <div title={config?.data?.modalities.toString()} style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', marginLeft: '10px', display: isAssigningKey ? 'none' : 'block', marginRight: '10px' }}>
                                                    {config?.data?.modalities.toString()}
                                                </div> */}

                                                <KeyboardButton id={`${config.id}_shortcut`} style={{ color: selectedAnnotations.includes(config.id) ? "#FFFFFF" : "#207DEA", display: config.isInEditMode ? "none" : "block", background: selectedAnnotations.includes(config.id) ? "#207DEA" : "#E5F5FD", marginRight: '3px', marginLeft: 'auto' }}>
                                                    {getPrettyHotkeyTrigger(config)}
                                                </KeyboardButton>
                                            </div>
                                        </AnnotationDiv>
                                    )
                                })}
                            </ShortcutsContainer>
                        )
                    }
                })}
            </div>
        </div>)
}

const ModalContentVertical = styled.div`
    padding: 20px 20px 5px;
`

const ShortcutsContainer = styled.div`
    min-height: 550px;
    max-height: 550px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 20px;

    scrollbar-width: thin;
    ::-webkit-scrollbar {
        display: block;
        width: 8px;
        color: #313A4A;
    }
    ::-webkit-scrollbar-track {
        background: #BEC4CF;
        width: 8px;
        border-radius: 2px;
    }
`

const AnnotationDiv = styled.div`
    width: 100%;
    height: 24px;
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.backgroundColor};
    p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin-left: 3px;
    }
`;

const KeyboardButton = styled.div`
    height: 24px;
    width: fit-content;
    background: #EFF1F4;
    padding: 1.5px 10px;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    text-transform: lowercase;
    color: #5F6775;
    margin-right: 3px;
`

// Utility function to convert hex color to rgb
function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null
}

const ColorCircle = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-image: linear-gradient(
        90deg,
        ${props => props.color} 50%,
        ${props => `rgba(${hexToRgb(props.color)}, ${props.opacity})`} 50%
    )
`