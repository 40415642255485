import React, { useContext, useRef } from "react"
import { useRecoilValue } from "recoil"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { annotationsAtom } from "../../../../Atoms/Annotations"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { D3PersystEEGWindow } from "../D3/D3PersystEEGWindow"
import { PersystEEGImagePageManager } from "../../../../Data/PersystEEGImagePageManager"
import { PersystEEGWindowSettings } from "./PersystEEGWindowSettings"
import { PersystEEGWindowConfig, PersystEEGWindowJSON } from "../../../../Types/PersystWindow"
import { persystEEGAtom } from "../../../../Atoms/PersystWindow"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"

export type PersystEEGWindowProps = {
	windowId: string
	json: PersystEEGWindowJSON
}

export const PersystEEGWindow = (props: PersystEEGWindowProps) => { 
	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<div style={{ flex: 0 }}>
				<PersystEEGWindowSettings windowId={props.windowId} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<PersystEEGVisualization {...props} />
				</DimensionsProvider>
			</div>
		</div>
		
	)
}

export const PersystEEGVisualization = (props: PersystEEGWindowProps) => {
	const container = useRef<HTMLDivElement>(null)
	const { fileStartDate, fileEndDate } = useRecoilValue(currentPatientFileInfoAtom)
	const { getDataQuerySocket } = useSocketProvider()

	const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
	const atom = persystEEGAtom(visualizationId)
	const config = useRecoilValue(atom)
	const dimensions = useContext(DimensionsContext)

    const annotations = useRecoilValue(annotationsAtom)
    const { inProgressAnnotation } = useInProgressAnnotation(props.windowId)

	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_EEG, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration ?? 30 * 1000 })
	const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const d3Controller = useD3Controller<D3PersystEEGWindow, PersystEEGWindowConfig, PersystEEGWindowJSON, PersystEEGImagePageManager>({
		atom,
		nodeRef: container,
		initProps: props.json,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.PERSYST_EEG, new PersystEEGImagePageManager(), getDataQuerySocket),
		props: {
			id: props.windowId,
			annotations,
			inProgressAnnotation,
			viewScale,
			fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_EEG, fileStartDate, fileEndDate),
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3PersystEEGWindow(node, config, pageManager, callbacks)
		},
		extractJSON(config) {
            return {
                viewDuration: viewDuration,
                playbackSpeed: config.playbackSpeed,
				montage: config.montage
            }
        },
	})

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [
            hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
        ]
    })

	useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
			dimensions,
            viewDuration,
			montage: config.montage
        }
    })

	return <div ref={container} style={{ height: "100%" }} />
}