import { atom } from "recoil";
import { TraceDataConfig } from "../Types/Trace";
import { Analytic, PRxAnalysis } from "../Types/AnalysisDetails";
import { DataSource } from "../Types/DataSource";

const defaultPRxConfig: PRxAnalysis = {
    analytic: Analytic.PRx,
    calculationPeriodMs: 1000 * 60, // 1 minute
    calculationWindowMs: 1000 * 60 * 5, // 5 minutes
    ABP: "ABP_na",
    ICP: "ICP_na"
}

const defaultCPPOptConfig = {
    analytic: Analytic.CPPOpt,
    ABP: "ABP_na",
    ICP: "ICP_na",
    calculationWindowMs: 1000, // TODO: support this?
    calculationPeriodMs: 1000 // TODO: support this?
}

const defaultMAPOptConfig = {
    analytic: Analytic.MAPOpt,
    ABP: "ABP_na",
    STO2: "PtO2_na",
    calculationWindowMs: 1000, // TODO: support this?
    calculationPeriodMs: 1000 // TODO: support this?
}

export const analyticTracesAtom = atom<TraceDataConfig[]>({
    key: "analytics",
    default: [
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`, // It is okay if the ID changes, as long as it is always unique.
            units: "",
            name: "PRx",
            rawDataKey: "PRx",
            dataKey: JSON.stringify(defaultPRxConfig),
            isCompositePart: false,
            dataSource: DataSource.CURRENT_PATIENT,
            onDemandAnalysis: defaultPRxConfig,
        },
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`,
            name: "CPPOpt",
            rawDataKey: "CPPOpt",
            units: "mmHg",
            dataKey: JSON.stringify(defaultCPPOptConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            isCompositePart: true,
            compositeIndex: 0,
            onDemandAnalysis: defaultCPPOptConfig
        },
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`,
            name: "CPPOpt_LLA",
            rawDataKey: "CPPOpt_LLA",
            units: "mmHg",
            dataKey: JSON.stringify(defaultCPPOptConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            isCompositePart: true,
            compositeIndex: 1,
            onDemandAnalysis: defaultCPPOptConfig
        },
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`,
            name: "CPPOpt_ULA",
            rawDataKey: "CPPOpt_ULA",
            units: "mmHg",
            dataKey: JSON.stringify(defaultCPPOptConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            isCompositePart: true,
            compositeIndex: 2,
            onDemandAnalysis: defaultCPPOptConfig
        },
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`,
            name: "MAPOpt",
            rawDataKey: "MAPOpt",
            units: "mmHg",
            dataKey: JSON.stringify(defaultMAPOptConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            isCompositePart: true,
            compositeIndex: 0,
            onDemandAnalysis: defaultMAPOptConfig
        },
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`,
            name: "MAPOpt_LLA",
            rawDataKey: "MAPOpt_LLA",
            units: "mmHg",
            dataKey: JSON.stringify(defaultMAPOptConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            isCompositePart: true,
            compositeIndex: 1,
            onDemandAnalysis: defaultMAPOptConfig
        },
        {
            id: `${Math.random()}-${new Date(Date.now()).toISOString()}`,
            name: "MAPOpt_ULA",
            rawDataKey: "MAPOpt_ULA",
            units: "mmHg",
            dataKey: JSON.stringify(defaultMAPOptConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            isCompositePart: true,
            compositeIndex: 2,
            onDemandAnalysis: defaultMAPOptConfig
        }
    ]
})
