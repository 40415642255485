export enum Analytic {
    CPPOpt = "cppopt",
    MAPOpt = "mapopt",
    PRx = "prx",
    ROLLING_AVERAGE = "rolling_average",
}


export const getAnalyticDisplayName = (analytic: Analytic | undefined) => {
    switch (analytic) {
        case Analytic.PRx: return "PRx"
        case Analytic.CPPOpt: return "CPPOpt"
        case Analytic.ROLLING_AVERAGE: return "Rolling average"
        default: return ""
    }
}


// On-demand analytics are calculated along with the page request at request time.
export type OnDemandAnalysis = {
    calculationPeriodMs: number // How often we should calculate the analytic
    calculationWindowMs: number // How much data we need for the analytic
    analytic: Analytic // The analytic that we are calculating
}


export type PRxAnalysis = OnDemandAnalysis & {
    analytic: Analytic.PRx
    ABP: string
    ICP: string
}


export type CPPOptAnalysis = OnDemandAnalysis & {
    analytic: Analytic.CPPOpt
    ABP: string
    ICP: string
}


export type MAPOptAnalysis = OnDemandAnalysis & {
    analytic: Analytic.MAPOpt
    ABP: string
    STO2: string
}

export type GenericOnDemandAnalysis = OnDemandAnalysis & {
    rawDataKey: string
}

export const isGenericAnalytic = (analytic: Analytic | undefined) => {
    return analytic && new Set([
        Analytic.ROLLING_AVERAGE,
    ]).has(analytic)
}
